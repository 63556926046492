/* eslint-disable no-unused-vars */
import utilities from "helpers/utilities";
import { instances } from "../api_helper";
import * as url from "../url_helper";
import encStorage from "helpers/encStorage";
const storage = JSON.parse(encStorage.getItem("authUser"));
import { decode } from "helpers/jwt";
import CetakLaporanKemajuanV2 from "helpers/workers/pdf/laporanKemajuanV2";
import CetakLaporanKemajuan from "helpers/workers/pdf/laporanKemajuan";
import { toast } from "react-toastify";
import LaporanKemajuanPDF from "helpers/workers/pdf/laporanKemajuan";
const API_V2_URL =
  process.env.REACT_APP_API_V2_URL || "http://localhost:8088/api/v2";
export const getListLaporanKemajuan = params => {
  let URL = "";
  const jwtResult = decode(storage?.token);
  if (params.path === "/penelitian/laporan-kemajuan") {
    URL = `${url.ENDPOINT_LIST_LAPORAN_KEMAJUAN}/${jwtResult.id_personal}?jenis-kegiatan=1`;
  } else if (params.path === "/pengabdian/laporan-kemajuan") {
    URL = `${url.ENDPOINT_LIST_LAPORAN_KEMAJUAN}/${jwtResult.id_personal}?jenis-kegiatan=2`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};
// For (No Saga)
export const uploadFileLaporanKemajuan = async file => {
  const formData = new FormData();
  formData.append("path", "folder-testing/");
  formData.append("file", file);
  const res = await instances.request(url.ENDPOINT_LAPORAN_KEMAJUAN_FILE, {
    method: "POST",
    data: formData,
  });
  return res;
};
// End

export const uploadDokumenLaporanKemajuan = payload => {
  const formData = new FormData();
  formData.append("path", payload.path);
  formData.append("file", payload.file);
  return instances.request(url.ENDPOINT_LAPORAN_KEMAJUAN_FILE, {
    method: "POST",
    data: formData,
  });
};
export const uploadDokumenSptb = payload => {
  const formData = new FormData();
  if (window.location.pathname.includes("/pengabdian")) {
    formData.append(
      "path",
      "document/laporan-kemajuan-pengabdian/penggunaan-anggaran/"
    );
  } else {
    formData.append("path", "document/laporan-kemajuan/sptb/");
  }
  formData.append("file", payload.file);
  return instances.request(url.ENDPOINT_LAPORAN_KEMAJUAN_FILE, {
    method: "POST",
    data: formData,
  });
};
// eslint-disable-next-line no-unused-vars
export const getLaporanKemajuanDetail = ({ id, nidn }) => {
  let endpoint = "";
  // * Operator PT Start (Operator Skip Dulu, karena ketika get data null)
  // if (nidn) {
  //   endpoint = `${url.ENDPOINT_DETAIL_LAPORAN_KEMAJUAN}/${id}/1/${nidn}`; // nidn for operator
  // } else {
  //   // const jwtResult = decode(storage?.token); // nidn for dosen
  //   // endpoint = `${url.ENDPOINT_DETAIL_LAPORAN_KEMAJUAN}/${id}/1/${jwtResult.nidn}`; // nidn for dosen
  //   endpoint = `${url.ENDPOINT_DETAIL_LAPORAN_KEMAJUAN}/${id}/1`; // nidn for dosen
  // }
  // * Operator PT End
  if (window.location.pathname.includes("/pengabdian")) {
    if (nidn) {
      endpoint = `${url.ENDPOINT_DETAIL_LAPORAN_KEMAJUAN}/${id}/1/${nidn}`; // * For Operator
    } else {
      endpoint = `${url.ENDPOINT_DETAIL_LAPORAN_KEMAJUAN_PENGABDIAN}/${id}/1`;
    }
  } else {
    if (nidn) {
      endpoint = `${url.ENDPOINT_DETAIL_LAPORAN_KEMAJUAN}/${id}/1/${nidn}`; // * For Operator
    } else {
      endpoint = `${url.ENDPOINT_DETAIL_LAPORAN_KEMAJUAN}/${id}/1`;
    }
  }
  return instances.request(endpoint, {
    method: "GET",
  });
};
export const postLaporanKemajuan = data => {
  console.log(data);
  return "post laporan kemajuan";
};
export const updateLaporanKemajuan = data => {
  console.log(data);
  return "update laporan kemajuan";
};

// * Create Laporan Kemajuan
// * No Saga
export const createLaporanKemajuan = async data => {
  // console.log(data);
  const res = await instances.request("/penelitian/laporan-kemajuan", {
    method: "POST",
    data: data,
  });
  return res;
};

// * Create Laporan Kemajuan
// * Saga
export const createLaporanKemajuanSaga = data => {
  let URL = "";
  if (window.location.pathname.includes("/pengabdian")) {
    URL = "/pengabdian/laporan-kemajuan";
  } else {
    URL = "/penelitian/laporan-kemajuan";
  }
  return instances.request(URL, {
    method: "POST",
    data: data,
  });
};

// * Get Criteria
// * Saga
export const getCriteria = criteria => {
  return instances.request(`/enumeration?criteria=group:${criteria}`, {
    method: "GET",
  });
};

// * Get Jenis Target Jenis Luaran
export const getTargetJenisLuaran = id_jenis_luaran => {
  let URL = "";
  if (window.location.pathname.includes("/pengabdian")) {
    URL = `/luaran/target-jenis-luaran/get-by-jenis-luaran/${id_jenis_luaran}?jenis=2`;
  } else {
    URL = `/luaran/target-jenis-luaran/get-by-jenis-luaran/${id_jenis_luaran}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

// * Get Dokumen Luaran Laporan Kemajuan
export const getDokumenLuaran = id_target_jenis_luaran => {
  let URL = "";
  if (window.location.pathname.includes("/pengabdian")) {
    URL = `/luaran/jenis-dokumen-bukti-luaran?criteria=id_target_jenis_luaran:${id_target_jenis_luaran}&page=1:1000&jenis=2`;
  } else {
    URL = `/luaran/jenis-dokumen-bukti-luaran?criteria=id_target_jenis_luaran:${id_target_jenis_luaran}&page=1:1000`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

// * Get Jenis Target Jenis Luaran No Saga
export const getTargetJenisLuaranNoSaga = async id_jenis_luaran => {
  let URL = "";
  if (window.location.pathname.includes("/pengabdian")) {
    URL = `/luaran/target-jenis-luaran/get-by-jenis-luaran/${id_jenis_luaran}?jenis=2`;
  } else {
    URL = `/luaran/target-jenis-luaran/get-by-jenis-luaran/${id_jenis_luaran}`;
  }
  const res = await instances.request(URL, {
    method: "GET",
  });
  return res;
};

// * Get Dokumen Luaran Laporan Kemajuan  No Saga
export const getDokumenLuaranNoSaga = async id_target_jenis_luaran => {
  let URL = "";
  if (window.location.pathname.includes("/pengabdian")) {
    URL = `/luaran/jenis-dokumen-bukti-luaran?criteria=id_target_jenis_luaran:${id_target_jenis_luaran}&page=1:1000&jenis=2`;
  } else {
    URL = `/luaran/jenis-dokumen-bukti-luaran?criteria=id_target_jenis_luaran:${id_target_jenis_luaran}&page=1:1000`;
  }
  const res = await instances.request(URL, {
    method: "GET",
  });
  return res;
};

// * Post SPTB No Saga
export const createSptb = async data => {
  const res = await instances.request(
    `/tanggung-jawab-belanja/usulan-kegiatan`,
    {
      method: "POST",
      data: data,
    }
  );
  return res;
};
export const updateSptb = async (id, data) => {
  const res = await instances.request(
    `/tanggung-jawab-belanja/usulan-kegiatan/${id}`,
    {
      method: "PUT",
      data: data,
    }
  );
  return res;
};
export const createDokumenMitra = async data => {
  const res = await instances.request(`/luaran/dokumen-mitra`, {
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
};

export const getDokumenMitra = async ({ id_transaksi_kegiatan }) => {
  return await instances.request(
    `/luaran/dokumen-mitra?criteria=id_transaksi_kegiatan:${id_transaksi_kegiatan}&sort=tgl_created:desc`,
    {
      method: "GET",
    }
  );
};
// ********************************************************
// * Post Dokumen Presentasi Pengabdian ( No Saga)
export const postDokumenPresentasi = async data => {
  data.kode_tahapan_kegiatan = "31";
  const res = await instances.request(`/luaran/presentasi-pelaksanaan`, {
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
};

// * Get Dokumen Presentasi Pengabdian ( No Saga)
export const getDokumentPresentasi = async id_usulan_kegiatan => {
  const res = await instances.request(
    `/luaran/presentasi-pelaksanaan?criteria=id_usulan_kegiatan:${
      utilities.uuidValidateV4(id_usulan_kegiatan) ? id_usulan_kegiatan : null
    },kode_tahapan_kegiatan:31&sort=tgl_created:desc`,
    {
      method: "GET",
    }
  );
  return res;
};
// * Post Dokumen Poster Pengabdian ( No Saga)
export const postDokumenPoster = async data => {
  data.kode_tahapan_kegiatan = "31";
  const res = await instances.request(`/luaran/poster`, {
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
};

// * Get Dokumen Presentasi Pengabdian ( No Saga)
export const getDokumenPoster = async id_usulan_kegiatan => {
  const res = await instances.request(
    `/luaran/poster?criteria=id_usulan_kegiatan:${
      utilities.uuidValidateV4(id_usulan_kegiatan) ? id_usulan_kegiatan : null
    },kode_tahapan_kegiatan:31&sort=tgl_created:desc`,
    {
      method: "GET",
    }
  );
  return res;
};
// ********************************************************

// ********************************************************

// * Get Luaran Tambahan Pengabdian ( No Saga)
export const getLuaranTambahanPengabdian = async id_usulan_kegiatan => {
  const res = await instances.request(
    `/luaran/luaran-pengabdian-tambahan?criteria=id_usulan_kegiatan:${
      utilities.uuidValidateV4(id_usulan_kegiatan) ? id_usulan_kegiatan : null
    },kode_tahapan_kegiatan:31&sort=tgl_created:desc`,
    {
      method: "GET",
    }
  );
  return res;
};
// * Get Jenis Luaran Tambahan Pengabdian ( No Saga)
export const getJenisLuaranTambahanPengabdian = async (
  group = "luaran_tambahan_pengabdian"
) => {
  const res = await instances.request(
    `/enumeration?criteria=(exact)group:${group}`,
    {
      method: "GET",
    }
  );
  return res;
};
// * POST Luaran Tambahan Pengabdian ( No Saga)
export const postLuaranTambahanPengabdian = async data => {
  data.kode_tahapan_kegiatan = "31";
  const res = await instances.request(`/luaran/luaran-pengabdian-tambahan`, {
    method: "POST",
    data: data,
  });
  return res;
};
// * PUT Luaran Tambahan Pengabdian ( No Saga)
export const putLuaranTambahanPengabdian = async (data, id) => {
  data.kode_tahapan_kegiatan = "31";
  const res = await instances.request(
    `/luaran/luaran-pengabdian-tambahan/${id}`,
    {
      method: "PUT",
      data: data,
    }
  );
  return res;
};
// ********************************************************

// *****************************************************************
// * Get Rencana Hasil Pelaksaan ( No Saga)
export const getRencanaHasilPelaksanaanPengabdian =
  async id_usulan_kegiatan => {
    const res = await instances.request(
      `/luaran/rencana-hasil-pelaksanaan?criteria=id_usulan_kegiatan:${
        utilities.uuidValidateV4(id_usulan_kegiatan) ? id_usulan_kegiatan : null
      },kode_tahapan_kegiatan:31&sort=tgl_created:desc`,
      {
        method: "GET",
      }
    );
    return res;
  };
// * Get Jenis Luaran Rencana Hasil Pelaksanaan( No Saga)
export const getJenisLuaranRencanaHasilPelaksanaanPengabdian = async () => {
  const res = await instances.request(
    `/enumeration?criteria=group:rencana_hasil_pelaksanaan`,
    {
      method: "GET",
    }
  );
  return res;
};
// * POST Rencana Hasil Pelaksanaan ( No Saga)
export const postRencanaHasilPelaksanaanPengabdian = async data => {
  data.kode_tahapan_kegiatan = "31";
  const res = await instances.request(`/luaran/rencana-hasil-pelaksanaan`, {
    method: "POST",
    data: data,
  });
  return res;
};
// * PUT Rencana Hasil Pelaksanaan ( No Saga)
export const putRencanaHasilPelaksanaanPengabdian = async (data, id) => {
  data.kode_tahapan_kegiatan = "31";
  const res = await instances.request(
    `/luaran/rencana-hasil-pelaksanaan/${id}`,
    {
      method: "PUT",
      data: data,
    }
  );
  return res;
};

// ********************************************************
// * Get Cetak Dokumen
export const getBerkasLaporanKemajuan = async ({
  id,
  kode_tahapan = null,
  jenis_laporan = "",
}) => {
  // kode_tahapan = 31 -> laporan kemajuan
  // kode_tahapan = 34 -> laporan akhir
  let URL = url.ENPOINT_CETAK_DOKUMEN_LAPORAN_AKHIR_AND_KEMAJUAN;
  if (
    window.location.pathname.includes("/penelitian/laporan-kemajuan") ||
    window.location.pathname.includes("/pengabdian/laporan-kemajuan") ||
    jenis_laporan === "laporan-kemajuan"
  ) {
    URL += `/${id}?kode_tahapan=31`;
  } else if (
    window.location.pathname.includes("/penelitian/laporan-akhir") ||
    window.location.pathname.includes("/pengabdian/laporan-akhir") ||
    window.location.pathname.includes("/detail-pengabdian-monev") ||
    window.location.pathname.includes("/detail-penelitian-monev") ||
    window.location.pathname.includes("/reviewer/penilaian-luaran") ||
    jenis_laporan === "laporan-akhir"
  ) {
    URL += `/${id}?kode_tahapan=34`;
  } else {
    URL += `/${id}?kode_tahapan=${kode_tahapan}`;
  }
  const res = await instances.request(URL, {
    method: "GET",
  });
  return res;
};

export const getAllDataPengabdianLaporanKemajuan = async ({
  id_usulan_kegiatan,
}) => {
  const response = await Promise.all([
    instances.request(
      `/pengabdian/laporan-kemajuan/by-id-usulan-kegiatan/${id_usulan_kegiatan}/1`,
      {
        method: "GET",
      }
    ),
    instances.request(
      `/luaran/rencana-hasil-pelaksanaan?criteria=id_usulan_kegiatan:${id_usulan_kegiatan},kode_tahapan_kegiatan:31&sort=tgl_created:desc`,
      {
        method: "GET",
      }
    ),
    instances.request(
      `/luaran/presentasi-pelaksanaan?criteria=id_usulan_kegiatan:${id_usulan_kegiatan},kode_tahapan_kegiatan:31&sort=tgl_created:desc`,
      {
        method: "GET",
      }
    ),
    instances.request(
      `/luaran/poster?criteria=id_usulan_kegiatan:${id_usulan_kegiatan},kode_tahapan_kegiatan:31&sort=tgl_created:desc`,
      {
        method: "GET",
      }
    ),
  ]);
  const result = {
    dataLaporanKemajuan: response[0].data.data,
    dataRencanaHasilPelaksana: response[1].data.data[0],
    dataDokumenPresentasi: response[2].data.data[0],
    dataDokumenPoster: response[3].data.data[0],
  };
  return result;
};
export const getAllDataPenelitianLaporanKemajuan = async ({
  id_usulan_kegiatan,
  id_transaksi_kegiatan,
}) => {
  const response = await Promise.all([
    instances.request(
      `/penelitian/laporan-kemajuan/by-id-usulan-kegiatan/${id_usulan_kegiatan}/1`,
      {
        method: "GET",
      }
    ),
    instances.request(
      `/luaran/dokumen-mitra?criteria=id_transaksi_kegiatan:${id_transaksi_kegiatan}&sort=tgl_created:desc`,
      {
        method: "GET",
      }
    ),
  ]);

  const result = {
    dataLaporanKemajuan: response[0].data.data,
    dataDokumenMitra: response[1].data.data?.[0],
  };
  return result;
};
// Laporan Kemajuan V2
export const getListLaporanKemajuanV2 = queryParams => {
  return instances.request({
    baseURL: API_V2_URL,
    method: "GET",
    url: `/laporan/kemajuan?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(queryParams)
    ).toString()}`,
  });
};
export const getDetailLaporanKemajuanV2 = async payload => {
  const id = payload.id_proposal_kegiatan
    ? payload.id_proposal_kegiatan
    : window.location.pathname.split("/")[3];
  return await instances.request({
    baseURL: API_V2_URL,
    method: "GET",
    url: `/laporan/kemajuan/${id}`,
  });
};
export const getTargetJenisLuaranV2NoSaga = async (
  id_jenis_luaran,
  jenis_program
) => {
  let URL;
  if (
    window.location.pathname.includes(
      "/kosabangsa/laporan-kemajuan-proposal"
    ) ||
    jenis_program === "pengabdian"
  ) {
    URL = `/luaran/target-jenis-luaran/get-by-jenis-luaran/${id_jenis_luaran}?jenis=2`;
  } else {
    URL = `/luaran/target-jenis-luaran/get-by-jenis-luaran/${id_jenis_luaran}`;
  }
  const res = await instances.request(URL, {
    method: "GET",
  });
  return res;
};
export const getDokumenLuaranV2NoSaga = async (
  id_target_jenis_luaran,
  jenis
) => {
  let URL;
  if (
    window.location.pathname.includes(
      "/kosabangsa/laporan-kemajuan-proposal"
    ) ||
    jenis === "kosabangsa" ||
    jenis === "pengabdian"
  ) {
    URL = `/luaran/jenis-dokumen-bukti-luaran?criteria=id_target_jenis_luaran:${id_target_jenis_luaran}&page=1:1000&jenis=2`;
  } else if (
    window.location.pathname.includes("/prototipe/laporan-kemajuan-proposal") ||
    jenis === "prototipe" ||
    jenis === "penelitian"
  ) {
    URL = `/luaran/jenis-dokumen-bukti-luaran?criteria=id_target_jenis_luaran:${id_target_jenis_luaran}&page=1:1000`;
  }
  const res = await instances.request(URL, {
    method: "GET",
  });
  return res;
};
export const updateLaporanKemajuanV2 = async payload => {
  const windowQueryParams = new URLSearchParams(window.location.search);
  if (payload.type === "draft" && windowQueryParams.get("mode") !== "dev") {
    return instances.request({
      baseURL: API_V2_URL,
      url: "/laporan/kemajuan/save-draft",
      method: "PUT",
      data: payload.data,
    });
  } else if (
    payload.type === "submit" &&
    windowQueryParams.get("mode") !== "dev"
  ) {
    return instances.request({
      baseURL: API_V2_URL,
      url: "/laporan/kemajuan/save-submit",
      method: "PUT",
      data: payload.data,
    });
  } else if (windowQueryParams.get("mode") === "dev") {
    return instances.request({
      baseURL: API_V2_URL,
      url: "/laporan/kemajuan/save-draft?mode=dev",
      method: "PUT",
      data: payload.data,
    });
  }
};

export const downloadLaporanKemajuanv2 = async (data, cb, flag = 1) => {
  let {
    raw: { identitas },
  } = data.proposal;
  try {
    const fileName = `${identitas?.judul.replace(/[^a-zA-Z0-9 ]/g, "").trim()}`;
    // * Generate Pdf From Client
    const proposalPDF = new CetakLaporanKemajuanV2({
      ...data,
    });
    //parameter 1 generete berisi jenis kegiatan
    //parameter 2 generete berisi flag jenis kegiatan  1 = regular, 2 = kosabangsa, 3 = prototipe;
    const blobFileMainContent = await proposalPDF.generate(
      data.kegiatan,
      data.flag_jenis_kegiatan
    );

    // * Get Pdf File From Server
    const blobFileFromServer = await proposalPDF.getMultiplefile();
    const contentMerge = [blobFileMainContent, ...blobFileFromServer];
    const mergingFun = () => {
      //merging version 1 for merge common files
      //merging version 2 for merge files ignoring encrypted file
      //merging version 3 for merge files ignoring encrypted file & lost merged the file
      proposalPDF.mergingv3(contentMerge, result => {
        const resultMerged = result.res;
        if (result.error === false) {
          if (flag !== 2) {
            proposalPDF.forceDownload(resultMerged, fileName + ".pdf");
          }
          cb({
            success: true,
            data: resultMerged,
          });
        } else {
          mergingFun();
          toast.warn("Beberapa file tidak dapat di gabung!");
          proposalPDF.forceDownload(
            contentMerge[result.indexBlobSkipped],
            `file_${result.indexBlobSkipped - 1}.pdf`
          );
        }
      });
    };

    mergingFun();
  } catch (error) {
    console.log(error);

    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};
export const downloadPdfLaporanKemajuanV1 = async (
  item,
  cb = () => {},
  jenis_kegiatan = 1
) => {
  try {
    // jenis kegiatan 1 -> Penelitian, 2 -> Pengabdian
    if (jenis_kegiatan === 1) {
      const extension = utilities
        .getFriendlyURL(item.url_file_substansi)
        .split(".")
        .pop();
      if (extension === "docx" || extension === "doc") {
        // * Generate Pdf From Client
        const laporanKemajuanPdf = new LaporanKemajuanPDF(item);
        const blobFileFromClient = await laporanKemajuanPdf.generate();
        laporanKemajuanPdf.forceDownload(blobFileFromClient, item.title);

        // * Force Download Docx
        const blobFileFromServerDocx = await laporanKemajuanPdf.getSingleFile(
          item?.url_file_substansi || ""
        );
        // * Force Download
        laporanKemajuanPdf.forceDownload(
          blobFileFromServerDocx,
          `${item.title}.${extension}`,
          true
        );
        return;
      }

      // * Generate Pdf From Client
      const laporanKemajuanPdf = new LaporanKemajuanPDF(item);
      const blobFileFromClient = await laporanKemajuanPdf.generate();

      // * Get Pdf File From Server
      const blobFileFromServer = await laporanKemajuanPdf.getSingleFile(
        item?.url_file_substansi || ""
      );
      // * Start Merger
      const mergedPdf = await laporanKemajuanPdf.merging([
        blobFileFromClient,
        blobFileFromServer,
      ]);
      // * Force Download
      laporanKemajuanPdf.forceDownload(mergedPdf, item.title);
      cb({
        success: true,
        data: mergedPdf,
      });
    } else if (jenis_kegiatan === 2) {
      const laporanKemajuanPdf = new LaporanKemajuanPDF(item);
      const blobFileFromClient = await laporanKemajuanPdf.generate();
      // * Get Pdf File From Server
      const blobFileFromServer =
        await laporanKemajuanPdf.getMultiplefileWithDocx(item);
      const mergedBlob = [blobFileFromClient, ...blobFileFromServer];
      // * Start Merger
      const mergedPdf = await laporanKemajuanPdf.merging(mergedBlob);
      // * Force Download
      laporanKemajuanPdf.forceDownload(mergedPdf, item.title);
      cb({
        success: true,
        data: mergedPdf,
      });
    }
  } catch (err) {
    console.error(err);
    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};
export const downloadLaporanKemajuan = async (data, cb, flag = 1) => {
  let { identitas } = data;
  try {
    const fileName = `${identitas?.judul.replace(/[^a-zA-Z0-9 ]/g, "").trim()}`;
    // * Generate Pdf From Client
    const proposalPDF = new CetakLaporanKemajuan({
      ...data,
    });

    const blobFileMainContent = await proposalPDF.generatev2();
    const contentMerge = [blobFileMainContent];
    // * Get Pdf File From Server
    if (data.laporan_kemajuan?.url_file_substansi) {
      const blobSubstansiFromServer = await proposalPDF.getSingleFile(
        data.laporan_kemajuan?.url_file_substansi
      );
      contentMerge.push(blobSubstansiFromServer);
    }
    if (data.laporan_kemajuan?.url_luaran_artikel) {
      const blobFileFromServer = await proposalPDF.getSingleFile(
        data.laporan_kemajuan?.url_luaran_artikel
      );
      if (blobFileFromServer) {
        contentMerge.push(blobFileFromServer);
      }
    }
    if (data.laporan_kemajuan?.url_luaran_media_massa) {
      const blobFileFromServer = await proposalPDF.getSingleFile(
        data.laporan_kemajuan?.url_luaran_media_massa
      );

      if (blobFileFromServer) {
        contentMerge.push(blobFileFromServer);
      }
    }

    if (data.laporan_kemajuan?.url_dokumen_rencana_hasil_pelaksanaan) {
      const blobFileFromServer = await proposalPDF.getSingleFile(
        data.laporan_kemajuan?.url_dokumen_rencana_hasil_pelaksanaan
      );
      if (blobFileFromServer) {
        contentMerge.push(blobFileFromServer);
      }
    }
    if (data.filesPengabdian) {
      for (const file of data.filesPengabdian) {
        if (file.url_dokumen) {
          const blobFileFromServer = await proposalPDF.getSingleFile(
            file.url_dokumen
          );
          if (blobFileFromServer) {
            contentMerge.push(blobFileFromServer);
          }
        }
      }
    }

    const mergingFun = () => {
      //merging version 1 for merge common files
      //merging version 2 for merge files ignoring encrypted file
      //merging version 3 for merge files ignoring encrypted file & lost merged the file
      proposalPDF.mergingv3(contentMerge, result => {
        const resultMerged = result.res;

        if (result.error === false) {
          if (flag !== 2) {
            proposalPDF.forceDownload(resultMerged, fileName + ".pdf");
          }
          cb({
            success: true,
            data: resultMerged,
          });
        } else {
          mergingFun();
          toast.warn("Beberapa file tidak dapat di gabung!");
          proposalPDF.forceDownload(
            contentMerge[result.indexBlobSkipped],
            `file_${result.indexBlobSkipped - 1}.pdf`
          );
        }
      });
    };

    mergingFun();
  } catch (error) {
    console.log(error);

    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};

export const getFilesLaporanKemajuanPengabdianRegulerMonev = async ({
  id,
  nidn,
}) => {
  // Get Laporan Kemajuan Detail
  const resLapKemajuanDetail = await instances.request(
    `${url.ENDPOINT_DETAIL_LAPORAN_KEMAJUAN_PENGABDIAN}/${id}/1/${nidn}`,
    {
      method: "GET",
    }
  );
  if (resLapKemajuanDetail.data.data) {
    // Ambil luaran publikasi jurnal & konferensi
    const data = resLapKemajuanDetail.data.data || {};
    const luaran = (data?.luaran || []).filter(item =>
      ["publikasi_jurnal", "konferensi"].includes(item.group_jenis_luaran)
    );
    let files_luaran = [];
    for (const item of luaran) {
      const res = await getDokumenLuaranNoSaga(item.id_target_jenis_luaran);
      const options_dokumen_luaran = res.data.data;
      const dokumen_bukti_luaran = item.data_luaran.dokumen_bukti_luaran
        .filter(f => f.id_target_luaran === item.data_luaran.id_target_luaran)
        .sort((a, b) => new Date(b.tgl_unggah) - new Date(a.tgl_unggah));
      const filteredFiles = dokumen_bukti_luaran.filter(item1 => {
        return options_dokumen_luaran.some(
          item2 =>
            item1.id_jenis_dokumen_bukti_luaran ===
            item2.id_jenis_dokumen_bukti_luaran
        );
      });
      files_luaran = files_luaran.concat(
        filteredFiles.map(item => ({ url_dokumen: item.url_dokumen }))
      );
    }

    // Get Luaran Poster
    const resPoster = await getDokumenPoster(id);
    const filePoster = resPoster.data?.data[0]?.url_dokumen;
    // Merge Files
    const mergeFiles = [...files_luaran, { url_dokumen: filePoster }];
    const mappedAllFiles = mergeFiles.map(item => ({
      url_dokumen: item.url_dokumen,
    }));
    return { files: mappedAllFiles, luaran: data.luaran, success: true };
  } else {
    return { success: false, message: "Data laporan kemajuan tidak tersedia!" };
  }
};

export const getAllFilesLuaranLaporanKemajuanV2 = async (result, jenis) => {
  if (jenis === "prototipe") {
    const luaran = result.data.luaran;
    let files_luaran = [];
    for (const item of luaran) {
      const res = await getDokumenLuaranV2NoSaga(
        item.id_target_jenis_luaran,
        jenis
      );
      const options_dokumen_luaran = res.data.data;
      const dokumen_bukti_luaran = item.data_luaran.dokumen_bukti_luaran
        .filter(f => f.id_target_luaran === item.data_luaran.id_target_luaran)
        .sort((a, b) => new Date(b.tgl_unggah) - new Date(a.tgl_unggah));
      const filteredFiles = dokumen_bukti_luaran.filter(item1 => {
        return options_dokumen_luaran.some(
          item2 =>
            item1.id_jenis_dokumen_bukti_luaran ===
            item2.id_jenis_dokumen_bukti_luaran
        );
      });
      files_luaran = files_luaran.concat(
        filteredFiles.map(item => item.url_dokumen)
      );
    }
    return files_luaran;
  } else if (jenis === "kosabangsa") {
    const luaran = result.data.luaran.filter(
      item =>
        ![
          "mitra",
          "pendampingan_pencarian",
          "pendampingan_penerapan",
          "",
        ].includes(item.group_jenis_luaran)
    );
    let files_luaran = [];
    for (const item of luaran) {
      const res = await getDokumenLuaranV2NoSaga(
        item.id_target_jenis_luaran,
        jenis
      );
      const options_dokumen_luaran = res.data.data;
      const dokumen_bukti_luaran = item.data_luaran.dokumen_bukti_luaran
        .filter(f => f.id_target_luaran === item.data_luaran.id_target_luaran)
        .sort((a, b) => new Date(b.tgl_unggah) - new Date(a.tgl_unggah));
      const filteredFiles = dokumen_bukti_luaran.filter(item1 => {
        return options_dokumen_luaran.some(
          item2 =>
            item1.id_jenis_dokumen_bukti_luaran ===
            item2.id_jenis_dokumen_bukti_luaran
        );
      });
      files_luaran = files_luaran.concat(
        filteredFiles.map(item => item.url_dokumen)
      );
    }
    files_luaran = files_luaran.concat(
      result.data?.raw?.luaran_static_bast?.url_file_bast || ""
    );
    return files_luaran;
  } else if (jenis === "penelitian") {
    const luaran = result.data.luaran.filter(
      item =>
        item.data_luaran !== null &&
        ![
          "poster",
          "video_kegiatan",
          "dokumen",
          "feasibility_study",
          "",
        ].includes(item.group_jenis_luaran)
    );
    let files_luaran = [];
    for (const item of luaran) {
      const res = await getDokumenLuaranV2NoSaga(
        item.id_target_jenis_luaran,
        jenis
      );
      const options_dokumen_luaran = res.data.data;
      const dokumen_bukti_luaran = item.data_luaran.dokumen_bukti_luaran
        .filter(f => f.id_target_luaran === item.data_luaran.id_target_luaran)
        .sort((a, b) => new Date(b.tgl_unggah) - new Date(a.tgl_unggah));
      const filteredFiles = dokumen_bukti_luaran.filter(item1 => {
        return options_dokumen_luaran.some(
          item2 =>
            item1.id_jenis_dokumen_bukti_luaran ===
            item2.id_jenis_dokumen_bukti_luaran
        );
      });
      files_luaran = files_luaran.concat(
        filteredFiles.map(item => item.url_dokumen)
      );
    }
    return files_luaran;
  } else if (jenis === "pengabdian") {
    const luaran = result.data.luaran.filter(
      item =>
        !["hak_cipta", ""].includes(item.group_jenis_luaran) &&
        item.urutan_thn_kegiatan === result.data.urutan &&
        item.data_luaran !== null
    );
    let files_luaran = [];
    for (const item of luaran) {
      const res = await getDokumenLuaranV2NoSaga(
        item.id_target_jenis_luaran,
        jenis
      );
      const options_dokumen_luaran = res.data.data;
      const dokumen_bukti_luaran = item.data_luaran.dokumen_bukti_luaran
        .filter(f => f.id_target_luaran === item.data_luaran.id_target_luaran)
        .sort((a, b) => new Date(b.tgl_unggah) - new Date(a.tgl_unggah));
      const filteredFiles = dokumen_bukti_luaran.filter(item1 => {
        return options_dokumen_luaran.some(
          item2 =>
            item1.id_jenis_dokumen_bukti_luaran ===
            item2.id_jenis_dokumen_bukti_luaran
        );
      });
      files_luaran = files_luaran.concat(
        filteredFiles.map(item => item.url_dokumen)
      );
    }
    const { luaran_tambahan, rencana_hasil_pelaksana } = result.data.raw;
    files_luaran = files_luaran.concat([
      luaran_tambahan.url_file_tambahan,
      rencana_hasil_pelaksana.url_file_rencana_hasil_pelaksana,
    ]);
    return files_luaran;
  }
};
