import moment from "moment";
import "moment/locale/id";
import { version as uuidVersion } from "uuid";
import { validate as uuidValidate } from "uuid";
import encStorage from "helpers/encStorage";
export default {
  convertDate(val, format = "YYYY-MM-DD") {
    return moment(val).locale("id").format(format);
  },
  convertDateUTC(val, format = "YYYY-MM-DD") {
    return moment(val).utc().format(format);
  },
  today(format = "YYYY-MM-DD HH:mm:ss") {
    return moment().locale("id").format(format);
  },
  sleep: ms => new Promise(r => setTimeout(r, ms)),
  randomStr(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  capitalize(word) {
    return word
      ?.toLowerCase()
      .replace(/\w/, firstLetter => firstLetter.toUpperCase());
  },
  maskMoney(amount = 0) {
    return amount
      ? amount
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          .replace(/^(?!00[^0])0/, "0")
          .replace(/[^\d.-]+/g, "0")
          .replace(/^0+/g, "")
      : "0";
  },
  unmaskMoney(amount) {
    return amount ? parseInt(amount.toString().split(".").join("")) : 0;
  },
  maskNumber(value) {
    value = value
      .toString()
      // .replace(/^(?!00[^0])0/, "")
      .replace(/\D/g, "");
    return value;
  },
  maskDecimal(value = 0) {
    value = value
      .toString()
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");
    return value;
  },
  groupByV2(list = [], keyGetter = () => {}) {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  },
  groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  },
  toCurrency(num, separator = ".", decimal = 0, currency = "") {
    num = parseFloat(num);
    if (isNaN(num) == true || num == null) {
      return `${currency} 0`;
    } else {
      let result = num.toFixed(decimal).split(".");
      return (
        `${currency}` +
        result[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + `${separator}`) +
        (result[1] ? "," + result[1] : "")
      );
    }
  },
  isArrayEmpty(arr) {
    if (!arr) return true;
    return arr.length === 0;
  },
  objectLength(obj = {}) {
    var size = Object.keys(obj).length;
    return size;
  },
  getFilenameFromUrl(url = "", sparator) {
    const arr = url.toString().split(sparator);
    return arr[arr.length - 1];
  },
  getFriendlyURL(uri) {
    const symbols = [
      "+",
      "#",
      "!",
      "$",
      "?",
      "-",
      "=",
      "*",
      "@",
      "^",
      "&",
      "~",
      ";",
      "|",
      ",",
      '"',
      "%22",
      " ",
    ];
    symbols.forEach(x => {
      uri = uri.replaceAll(x, encodeURIComponent(x));
    });
    return uri;
  },
  readFileBlobAsync(blob) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  },

  blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  },

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  },
  b64toBlob(b64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  },
  yearsPresentToPast(back = 3, majuBerapaTahun = 0) {
    const year = new Date().getFullYear() + majuBerapaTahun;
    return Array.from({ length: back }, (_v, i) => year - back + i + 1);
  },
  yearsFutrureToPast(forward = 1, back = 3) {
    const year = new Date().getFullYear();
    const backs = Array.from({ length: back }, (_v, i) => year - back + i + 1);
    const future = Array.from(
      { length: forward },
      (_v, i) => year + forward + i
    );

    return [...future, ...backs].sort((a, b) => (a > b ? (b > a ? 0 : -1) : 1)); //sort dari yg terbesar
  },
  yearsPresentToFuture(forward = 3) {
    const year = new Date().getFullYear();
    return Array.from({ length: forward }, (_v, i) => year + i);
  },
  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },
  uuidValidateV4(uuid) {
    return uuidValidate(uuid) && uuidVersion(uuid) === 4;
  },
  sumObjectValues(object) {
    const arrayData = Object.values(object).reduce((x, y) => x + y, 0);
    return arrayData;
  },

  // Pretty Title
  // input Penelitian-dasar-unggulan-perguruan-tinggi
  // output PENELITIAN DASAR UNGGULAN PERGURUAN TINGGI
  prettyTitle(title) {
    return title.replace(/-/g, " ").toUpperCase();
  },

  async blobToArrayBuffer(blob) {
    if ("arrayBuffer" in blob) return await blob.arrayBuffer();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject();
      reader.readAsArrayBuffer(blob);
    });
  },
  async blobToUint8Array(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        resolve(new Uint8Array(event.target.result));
      };
      reader.onerror = () => {
        reject();
      };
      reader.readAsArrayBuffer(blob);
    });
  },
  limitText(text, limit) {
    if (text.length > limit) {
      return text.substring(0, limit) + "...";
    } else {
      return text;
    }
  },
  isURL(value) {
    const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
    return urlRegex.test(value);
  },
  formatJsonProperty(str) {
    const words = str.split("_");
    const parsedStr = words
      .map(word => {
        const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        return capitalizedWord;
      })
      .join(" ");
    return parsedStr;
  },
  getMaxYearByData(data = [], property) {
    const maxYear = Math.max(
      ...data.map(item => new Date(item[property]).getFullYear())
    );
    return maxYear;
  },
  getRangeYearByData(data = [], property) {
    const years = [
      ...new Set(data.map(item => new Date(item[property]).getFullYear())),
    ].sort((a, b) => b - a);
    return years;
  },
  parsePayloadToParamsStr(payload = {}) {
    let URL = "";

    if (JSON.stringify(payload) !== "{}") {
      URL += "?";
      Object.entries(payload).forEach(([key, value], i) => {
        URL += `${key}=${value}${
          i === Object.entries(payload).length - 1 ? "" : "&"
        }`;
      });
    }
    return URL;
  },
  // Clean and match sub-strings in a string.
  extractSubstr(str, regexp) {
    return (
      str
        .replace(/[^\w\s]|_/g, "")
        .replace(/\s+/g, " ")
        .toLowerCase()
        .match(regexp) || []
    );
  },

  // Find words by searching for sequences of non-whitespace characters.
  getWordsByNonWhiteSpace(str) {
    return this.extractSubstr(str || "", /\S+/g);
  },
  removeNullOrUndefinedProperties(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        delete obj[key];
      }
    });
    return obj;
  },
  isEmpty(data) {
    if (
      data === null ||
      data === undefined ||
      /^\s*$/.test(data) ||
      (Array.isArray(data) && data.length === 0) ||
      (typeof data === "object" &&
        data !== null &&
        Object.keys(data).length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  },
  resultEnumToOptions(data) {
    return data.map(item => ({
      label: item.val,
      value: item.val,
    }));
  },
  convertNominalRupiahToText(value, currency = "") {
    const units = ["Ribu", "Juta", "Miliar", "Triliun"];
    let unitIndex = -1;
    let scaledValue = value;
    while (scaledValue >= 1000 && unitIndex < units.length - 1) {
      scaledValue /= 1000;
      unitIndex++;
    }

    if (unitIndex === -1) {
      return currency + value.toString();
    }
    return currency + Math.round(scaledValue) + " " + units[unitIndex];
  },
  formatNumberWithDot(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  },
  getAuthUser() {
    const auth = JSON.parse(encStorage.getItem("authUser"));
    return auth;
  },
  getTextFromHtml(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  },
  isProduction: process.env.REACT_APP_NODE === "production",
};
