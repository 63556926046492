/* eslint-disable no-unused-vars */
import direktoratTypes from "constants/direktorat-types";
import roleTypeIds from "constants/role-type-ids";
import React from "react";
import encStorage from "helpers/encStorage";
import MENU_URL from "constants/menu-url";
const auth = JSON.parse(encStorage.getItem("authUser"));
const hideMenu = () => {
  const isVokasi = auth?.data?.kd_program_pendidikan === "2";
  if (isVokasi) {
    return true;
  } else {
    return true;
  }
};
// Dosen
const Dashboard = React.lazy(() => import("../pages/Dashboard/index"));
const FormIdentitas = React.lazy(() => import("../pages/Dashboard/form"));
const Notification = React.lazy(() => import("../pages/Notification/index"));
const SinkronisasiPPDIKTI = React.lazy(() =>
  import("../pages/Dashboard/syncPddikti")
);
const UsulanPenelitianForm = React.lazy(() =>
  import("../pages/Penelitian/Usulan/Form")
);
const UsulanPenelitianRABForm = React.lazy(() =>
  import("../pages/Penelitian/Usulan/rab/Form")
);
const UsulanPenelitianList = React.lazy(() =>
  import("../pages/Penelitian/Usulan")
);
const UsulanPenelitianDetail = React.lazy(() =>
  import("../pages/Penelitian/Usulan/Detail")
);
const UsulanPengabdianForm = React.lazy(() =>
  import("../pages/Pengabdian/Usulan/Form")
);
const UsulanPengabdianList = React.lazy(() =>
  import("../pages/Pengabdian/Usulan")
);
const UsulanPengabdianDetail = React.lazy(() =>
  import("../pages/Pengabdian/Usulan/Detail/index")
);
const UsulanPenelitianV2List = React.lazy(() =>
  import("../pages/ProposalV2/Penelitian/index")
);
const UsulanPenelitianV2Form = React.lazy(() =>
  import("../pages/ProposalV2/Penelitian/Form")
);
const UsulanPenelitianDetailV2 = React.lazy(() =>
  import("../pages/ProposalV2/Penelitian/Detail")
);

const UsulanPengabdianV2List = React.lazy(() =>
  import("../pages/ProposalV2/Pengabdian/index")
);
const UsulanPengabdianV2Form = React.lazy(() =>
  import("../pages/ProposalV2/Pengabdian/Form")
);
const UsulanPengabdianViewDetail = React.lazy(() =>
  import("../pages/ProposalV2/Pengabdian/ViewDetail")
);
const Identitas = React.lazy(() => import("../pages/Identitas/index"));
const Sinta = React.lazy(() => import("../pages/Sinta/index"));
const RiwayatPenelitian = React.lazy(() =>
  import("../pages/RiwayatPenelitian/index")
);
const RiwayatPengabdian = React.lazy(() =>
  import("../pages/RiwayatPengabdian/index")
);
const ArtikelJurnal = React.lazy(() => import("../pages/ArtikelJurnal/index"));
// Dosen - Usulan
const PerbaikanUsulan = React.lazy(() =>
  import("../pages/Penelitian/Usulan/Perbaikan")
);
const PerbaikanUsulanDetail = React.lazy(() =>
  import("../pages/Penelitian/Usulan/PerbaikanDetail")
);

// Dosen - Laporan Kemajuan Test
const LaporanKemajuanTest = React.lazy(() =>
  import("../components/LaporanKemajuan/TestForm")
);
// Dosen - Laporan Kemajan Cetak PDF Test
const CetakLaporanKemajuan = React.lazy(() =>
  import("../pages/Penelitian/LaporanKemajuanPenelitian/CetakLaporanKemajuan")
);

// * Dosen - Laporan Kemajuan Penelitian
const LaporanKemajuan = React.lazy(() =>
  import("../pages/Penelitian/LaporanKemajuanPenelitian")
);
const LaporanKemajuanDetail = React.lazy(() =>
  import("../pages/Penelitian/LaporanKemajuanPenelitian/Detail")
);

// * Dosen - Laporan Kemajuan Pengabdian
const LaporanKemajuanPengabdian = React.lazy(() =>
  import("../pages/Pengabdian/LaporanKemajuanPengabdian")
);
const LaporanKemajuanPengabdianDetail = React.lazy(() =>
  import("../pages/Pengabdian/LaporanKemajuanPengabdian/Detail")
);

// * Dosen - Laporan Akhir Penelitian
const LaporanAkhir = React.lazy(() =>
  import("../pages/Penelitian/LaporanAkhirPenelitian")
);
const LaporanAkhirDetail = React.lazy(() =>
  import("../pages/Penelitian/LaporanAkhirPenelitian/Detail")
);
const LaporanAkhirDetailAktual = React.lazy(() =>
  import("../pages/Penelitian/LaporanAkhirPenelitian/DetailAktual")
);

// * Dosen - Laporan Akhir Pengabdian
const LaporanAkhirPengabdian = React.lazy(() =>
  import("../pages/Pengabdian/LaporanAkhirPengabdian")
);
const LaporanAkhirPengabdianDetail = React.lazy(() =>
  import("../pages/Pengabdian/LaporanAkhirPengabdian/Detail")
);
const LaporanAkhirPengabdianDetailAktual = React.lazy(() =>
  import("../pages/Pengabdian/LaporanAkhirPengabdian/DetailAktual")
);

// Dosen - Catatan Harian
const CatatanHarian = React.lazy(() => import("../pages/CatatanHarian/index"));
const CatatanHarianDetail = React.lazy(() =>
  import("../pages/CatatanHarian/detail")
);
// Dosen - Penugasan
const PenugasanNusantara = React.lazy(() =>
  import("../pages/Penugasan/Nusantara")
);
const PenugasanPrototipe = React.lazy(() =>
  import("../pages/Penugasan/Prototipe")
);
const PenugasanJfsSeaEu = React.lazy(() => import("../pages/Penugasan/JFS"));
const PenugasanGoogleBangkit = React.lazy(() =>
  import("../pages/Penugasan/GoogleBangkit")
);
const PenugasanModulPembelajaran = React.lazy(() =>
  import("../pages/Penugasan/ModulPembelajaran")
);
const PenugasanKosaBangsa = React.lazy(() =>
  import("../pages/Penugasan/KosaBangsa")
);

// Dosen - Pendaftaran Reviewer
const PendaftaranReviewer = React.lazy(() =>
  import("../pages/Reviewer/Pendaftaran")
);

// Dosen - personel invitation
const PersonelInvitationPenelitian = React.lazy(() =>
  import("../pages/Penelitian/PersonelInvitation/index")
);
const PersonelInvitationPengabdian = React.lazy(() =>
  import("../pages/Pengabdian/PersonelInvitation/index")
);

//Sptb
const Sptb = React.lazy(() => import("../pages/Penelitian/Sptb"));

//Kosabangsa
const PelaksanaKosabangsa = React.lazy(() =>
  import("../pages/Kosabangsa/Pendaftaran/Pelaksana/index")
);
const PelaksanaKosabangsaForm = React.lazy(() =>
  import("../pages/Kosabangsa/Pendaftaran/Pelaksana/Form")
);
const PelaksanaKosabangsaDetail = React.lazy(() =>
  import("../pages/Kosabangsa/Pendaftaran/Pelaksana/Detail/index")
);
const PendampingKosabangsa = React.lazy(() =>
  import("../pages/Kosabangsa/Pendaftaran/Pendamping")
);
const PendampingKosabangsaForm = React.lazy(() =>
  import("../pages/Kosabangsa/Pendaftaran/Pendamping/Form")
);
const PendampingKosabangsaDetail = React.lazy(() =>
  import("../pages/Kosabangsa/Pendaftaran/Pendamping/Detail/index")
);
//Kosabangsa personel invitation
const PersonelInvitationKosabangsaPelaksana = React.lazy(() =>
  import("../pages/Kosabangsa/Pendaftaran/Pelaksana/PersonelInvitation")
);
const PersonelInvitationKosabangsaPendamping = React.lazy(() =>
  import("../pages/Kosabangsa/Pendaftaran/Pendamping/PersonelInvitation")
);
const PersonelInvitationKosabangsaKolaborasi = React.lazy(() =>
  import("../pages/Kosabangsa/v2024/PersonelInvitation")
);
//Kosabangsa Usulan
const FormUsulanKosabangsa = React.lazy(() =>
  import("../pages/Kosabangsa/Usulan/Form")
);
const FormUsulanKosabangsaV2024 = React.lazy(() =>
  import("../pages/Kosabangsa/v2024/index")
);
const UsulanKosabangsa = React.lazy(() => import("../pages/Kosabangsa/Usulan"));
const KosaBangsaDetail = React.lazy(() =>
  import("../pages/Kosabangsa/Usulan/Detail")
);

//Minat Program Kolaborasi Penelitian (Minat Katalis - start 2024)
const ListMinatPorgramKatalist = React.lazy(() =>
  import("../pages/ProposalV2/Penelitian/minatKatalis")
);
const FormMinatProgramKatalist = React.lazy(() =>
  import("../pages/ProposalV2/Penelitian/minatKatalis/FormMinatKatalis")
);

//Prototipe
const PrototipeList = React.lazy(() => import("../pages/Prototipe"));
const PrototipeForm = React.lazy(() => import("../pages/Prototipe/Form"));
const PorototipeUsulanDetail = React.lazy(() =>
  import("../pages/Prototipe/Detail")
);
const PrototipePersonelInvitation = React.lazy(() =>
  import("../pages/Prototipe/PersonelInvitation")
);

const CatatanHarianV2 = React.lazy(() =>
  import("../pages/CatatanHarianV2/index")
);
const CatatanHarianV2Detail = React.lazy(() =>
  import("../pages/CatatanHarianV2/detail")
);
//Laporan Kemajuan Proposal V2
const LaporanKemajuanPrototipeV2 = React.lazy(() =>
  import("../pages/LaporanKemajuanV2/Prototipe/index")
);
const LaporanKemajuanPrototipeDetailV2 = React.lazy(() =>
  import("../pages/LaporanKemajuanV2/Prototipe/Detail")
);
const LaporanKemajuanKosabangsaV2 = React.lazy(() =>
  import("../pages/LaporanKemajuanV2/Kosabangsa/index")
);
const LaporanKemajuanKosabangsaDetailV2 = React.lazy(() =>
  import("../pages/LaporanKemajuanV2/Kosabangsa/Detail")
);
const LaporanKemajuanPenelitianV2 = React.lazy(() =>
  import("../pages/LaporanKemajuanV2/Penelitian/index")
);
const LaporanKemajuanPenelitianDetailV2 = React.lazy(() =>
  import("../pages/LaporanKemajuanV2/Penelitian/Detail")
);
const LaporanKemajuanPengabdianV2 = React.lazy(() =>
  import("../pages/LaporanKemajuanV2/Pengabdian/index")
);
const LaporanKemajuanPengabdianDetailV2 = React.lazy(() =>
  import("../pages/LaporanKemajuanV2/Pengabdian/Detail")
);
//Laporan Akhir Proposal V2
const LaporanAkhirPrototipeV2 = React.lazy(() =>
  import("../pages/LaporanAkhirV2/Prototipe/index")
);
const LaporanAkhirPrototipeV2DetailV2 = React.lazy(() =>
  import("../pages/LaporanAkhirV2/Prototipe/Detail")
);
const LaporanAkhirKosabangsaV2 = React.lazy(() =>
  import("../pages/LaporanAkhirV2/Kosabangsa/index")
);
const LaporanAkhirKosabangsaV2DetailV2 = React.lazy(() =>
  import("../pages/LaporanAkhirV2/Kosabangsa/Detail")
);

//Revisi Proposal V2
const ProposalRevisiList = React.lazy(() =>
  import("../pages/PerbaikanProposal")
);
const ProposalRevisiForm = React.lazy(() =>
  import("../pages/PerbaikanProposal/Form")
);

//Bimtek Proposal
const ListBimtekProposal = React.lazy(() =>
  import("../pages/ProposalV2/Bimtek")
);
const FormBimtekProposalPenelitian = React.lazy(() =>
  import("../pages/ProposalV2/Penelitian/BimtekForm")
);
const FormBimtekProposalPengabdian = React.lazy(() =>
  import("../pages/ProposalV2/Pengabdian/BimtekForm")
);

//Kekayaan intelektual
const ListPendaftaranKI = React.lazy(() =>
  import("../pages/KI/PendaftaranKI/ListPendaftaranKI")
);
const InputPendaftaranKI = React.lazy(() =>
  import("../pages/KI/PendaftaranKI/InputPendaftaranKI")
);
const ReviewerKIDashAdm = React.lazy(() =>
  import("../pages/KI/ReviewerAdministrasi/ReviewerDashAdm")
);
const ReviewerKIDashBimtek = React.lazy(() =>
  import("../pages/KI/ReviewerBimtek/ReviewerDashBimtek")
);

// e-Catalog (Search Page)
const SearchEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Search/index.js")
);
export default [
  {
    id: "dashboard-dosen",
    label: "Dashboard",
    path: "/dashboard",
    sequence: 1,
    component: Dashboard,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penelitian-dosen",
    label: "Penelitian",
    sequence: 2,
    icon: "bx bx-search",
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "pengabdian-dosen",
    label: "Pengabdian",
    sequence: 3,
    icon: "bx bx-star",
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "kosabangsa-dosen",
    label: "Kosabangsa",
    sequence: 4,
    icon: "bx bx-shape-circle",
    direktorat: [direktoratTypes.DIKTI],
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "prototipe-dosen",
    label: "Prototipe",
    sequence: 5,
    icon: "bx bx-search-alt",
    roles: [roleTypeIds.DOSEN],
    direktorat: [direktoratTypes.DIKTI],
  },
  {
    id: "notification",
    path: "/notification",
    component: Notification,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "sinkronisasi-ppdikti",
    path: "/sync-pddikti",
    component: SinkronisasiPPDIKTI,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "form-identitas",
    path: "/form-identitas",
    component: FormIdentitas,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-form",
    path: "/penelitian/usulan/form",
    component: UsulanPenelitianForm,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-form",
    path: "/penelitian/usulan/rab-form/:id",
    component: UsulanPenelitianRABForm,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-list",
    path: "/penelitian/usulan",
    component: UsulanPenelitianList,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-detail",
    path: "/penelitian/usulan/form/:id",
    component: UsulanPenelitianForm,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-detail-submitted",
    path: "/penelitian/usulan/detail/:id",
    component: UsulanPenelitianDetail,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-list-pengabdian",
    path: "/pengabdian/usulan",
    component: UsulanPengabdianList,
    roles: [roleTypeIds.DOSEN],
    label: "Usulan Baru",
    hide: true,
    parent: "pengabdian-dosen",
  },
  {
    id: "usulan-pengabdian-detail",
    path: "/pengabdian/usulan/form",
    component: UsulanPengabdianForm,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-pengabdian-detail",
    path: "/pengabdian/usulan/form/:id",
    component: UsulanPengabdianForm,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-pengabdian-detail",
    path: "/pengabdian/usulan/detail/:id",
    component: UsulanPengabdianDetail,
    roles: [roleTypeIds.DOSEN],
  },
  // Usulan reguler v2 Penelitian
  {
    id: "usulan-list",
    path: "/penelitian/usulan/v2/form",
    component: UsulanPenelitianV2Form,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-list",
    path: "/penelitian/usulan/v2/form/:id",
    component: UsulanPenelitianV2Form,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-list",
    path: "/penelitian/usulan/v2/detail/:id",
    component: UsulanPenelitianDetailV2,
    roles: [roleTypeIds.DOSEN],
  },
  // Minat Katalist Penelitian
  {
    id: "form-penelitian-minat-katalis",
    path: "/penelitian/minat-katalis/form",
    component: FormMinatProgramKatalist,
    direktorat: [direktoratTypes.DIKTI],
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "form-penelitian-minat-katalis",
    path: "/penelitian/minat-katalis/form/:id",

    component: FormMinatProgramKatalist,
    direktorat: [direktoratTypes.DIKTI],
    roles: [
      roleTypeIds.DOSEN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },

  // Usulan reguler v2 pengabdian
  {
    id: "usulan-list",
    path: "/pengabdian/usulan/v2",
    component: UsulanPengabdianV2List,
    roles: [roleTypeIds.DOSEN],
    parent: "pengabdian-dosen",
    label: "Usulan Baru",
  },
  {
    id: "usulan-list",
    path: "/pengabdian/usulan/v2/form",
    component: UsulanPengabdianV2Form,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-list",
    path: "/pengabdian/usulan/v2/form/:id",
    component: UsulanPengabdianV2Form,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-list",
    path: "/pengabdian/usulan/v2/detail/:id",
    component: UsulanPengabdianViewDetail,
    roles: [roleTypeIds.DOSEN],
  },
  // identitas
  {
    id: "identitas-dosen",
    path: "/identitas",
    component: Identitas,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "sinta-dosen",
    path: "/sinta",
    component: Sinta,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "riwayat-penelitian-dosen",
    path: "/riwayat-penelitian",
    component: RiwayatPenelitian,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "riwayat-pengabdian-dosen",
    path: "/riwayat-pengabdian",
    component: RiwayatPengabdian,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "artikel-jurnal-dosen",
    path: "/artikel-jurnal",
    component: ArtikelJurnal,
    roles: [roleTypeIds.DOSEN],
  },

  //DOSEN - USULAN BARU
  {
    id: "usulan-penelitian-list",
    path: "/penelitian/usulan",
    hide: true,
    parent: "penelitian-dosen",
    label: "Usulan Baru",
    component: UsulanPenelitianForm,
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
    roles: [roleTypeIds.DOSEN],
  },

  {
    id: "usulan-penelitian-list-v2",
    path: "/penelitian/usulan/v2",
    parent: "penelitian-dosen",
    label: "Usulan Baru",
    component: UsulanPenelitianV2List,
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "list-penelitian-minat-katalis",
    path: "/penelitian/minat-katalis",
    parent: "penelitian-dosen",
    label: "Minat KATALIS",
    component: ListMinatPorgramKatalist,
    direktorat: [direktoratTypes.DIKTI],
    roles: [roleTypeIds.DOSEN],
  },
  // Catatan harian reguler v2 (penelitian dan pengabdian)
  {
    id: "dosen-catatan-harian-penelitian-v2",
    label: "Catatan Harian",
    path: "/penelitian/v2/catatan-harian",
    parent: "penelitian-dosen",
    roles: [roleTypeIds.DOSEN],
    sequence: 8,
  },
  {
    id: "dosen-catatan-harian-pengabdian-v2",
    label: "Catatan Harian",
    path: "/pengabdian/v2/catatan-harian",
    parent: "pengabdian-dosen",
    roles: [roleTypeIds.DOSEN],
    sequence: 7,
  },
  {
    id: "dosen-catatan-harian-v2",
    path: "/:jenis_usulan/v2/catatan-harian",
    component: CatatanHarianV2,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "dosen-catatan-harian-v2-detail",
    path: "/:jenis_usulan/v2/catatan-harian/:id",
    component: CatatanHarianV2Detail,
    roles: [roleTypeIds.DOSEN],
  },
  // Dosen - Usulan Perbaikan
  {
    id: "perbaikan-usulan-dosen",
    path: "/:jenis_usulan/revisi",
    component: PerbaikanUsulan,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "perbaikan-usulan-penelitian",
    label: "Perbaikan Usulan",
    parent: "penelitian-dosen",
    path: "/penelitian/revisi",
    roles: [roleTypeIds.DOSEN],
    hide: true,
  },
  {
    id: "perbaikan-usulan-pengabdian",
    label: "Perbaikan Usulan",
    parent: "pengabdian-dosen",
    path: "/pengabdian/revisi",
    roles: [roleTypeIds.DOSEN],
    hide: true,
  },
  {
    id: "perbaikan-usulan-detail-dosen",
    path: "/:jenis_usulan/revisi/:id",
    component: PerbaikanUsulanDetail,
    roles: [
      roleTypeIds.DOSEN,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  //usulan perbaikan proposal v2 (protoipe, kosabangsa disini juga)
  {
    id: "prototipe-dosen-usulan-perbaikan",
    path: "/usulan-revisi/:jenis_usulan",
    roles: [roleTypeIds.DOSEN],
    component: ProposalRevisiList,
  },
  {
    id: "prototipe-dosen-usulan-perbaikan",
    path: "/usulan-revisi/:jenis_usulan/form/:id",
    roles: [
      roleTypeIds.DOSEN,
      roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN_VOKASI,
      roleTypeIds.OPERATOR_PT_PENELITIAN_VOKASI,
    ],
    component: ProposalRevisiForm,
  },
  //Usulan Perbaikan Prototipe/Kosabangsa
  {
    id: "prototipe-dosen-usulan-perbaikan",
    label: "Perbaikan Usulan",
    path: "/usulan-revisi/prototipe",
    parent: "prototipe-dosen",
    roles: [roleTypeIds.DOSEN],
    sequence: 2,
  },
  {
    id: "kosabangsa-dosen-usulan-perbaikan",
    label: "Perbaikan Usulan",
    path: "/usulan-revisi/kosabangsa",
    parent: "kosabangsa-dosen",
    sequence: 4,
    roles: [roleTypeIds.DOSEN],
  },
  // END Usulan Perbaikan Prototipe/Kosabangsa
  // Usulan Perbaikan Regular V2
  {
    id: "penelitian-dosen-usulan-perbaikan",
    label: "Perbaikan Usulan",
    path: "/usulan-revisi/penelitian",
    parent: "penelitian-dosen",
    sequence: 4,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penelitian-dosen-usulan-perbaikan",
    label: "Perbaikan Usulan",
    path: "/usulan-revisi/pengabdian",
    parent: "pengabdian-dosen",
    sequence: 3,
    roles: [roleTypeIds.DOSEN],
  },
  // Laporan Kemajuan Reguler v1
  {
    id: "laporan-kemajuan-penelitian-dosen",
    path: "/penelitian/laporan-kemajuan",
    component: LaporanKemajuan,
    parent: "penelitian-dosen",
    // label: "Laporan Kemajuan",
    sequence: 5,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "laporan-kemajuan-penelitian-detail-dosen",
    path: "/penelitian/laporan-kemajuan/:id",
    component: LaporanKemajuanDetail,
    roles: [
      roleTypeIds.DOSEN,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  {
    id: "laporan-kemajuan-pengabdian-dosen",
    path: "/pengabdian/laporan-kemajuan",
    component: LaporanKemajuanPengabdian,
    parent: "pengabdian-dosen",
    // label: "Laporan Kemajuan",
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "laporan-kemajuan-pengabdian-detail-dosen",
    path: "/pengabdian/laporan-kemajuan/:id",
    component: LaporanKemajuanPengabdianDetail,
    roles: [
      roleTypeIds.DOSEN,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  // laporan kemajuan reguler v2
  {
    id: "laporan-kemajuan-penelitian-dosen-v2",
    path: "/penelitian/v2-laporan-kemajuan",
    component: LaporanKemajuanPenelitianV2,
    parent: "penelitian-dosen",
    label: "Laporan Kemajuan",
    // hide: process.env.REACT_APP_NODE === "production",
    sequence: 6,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "laporan-kemajuan-penelitian-detail-dosen-v2",
    path: "/penelitian/v2-laporan-kemajuan/:id",
    component: LaporanKemajuanPenelitianDetailV2,
    roles: [
      roleTypeIds.DOSEN,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  {
    id: "laporan-kemajuan-pengabdian-dosen-v2",
    path: "/pengabdian/v2-laporan-kemajuan",
    component: LaporanKemajuanPengabdianV2,
    parent: "pengabdian-dosen",
    // hide: process.env.REACT_APP_NODE === "production",
    sequence: 5,
    label: "Laporan Kemajuan",
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "laporan-kemajuan-pengabdian-detail-dosen",
    path: "/pengabdian/v2-laporan-kemajuan/:id",
    component: LaporanKemajuanPengabdianDetailV2,
    roles: [
      roleTypeIds.DOSEN,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  //
  {
    id: "laporan-akhir-penelitian-dosen",
    path: "/penelitian/laporan-akhir",
    component: LaporanAkhir,
    parent: "penelitian-dosen",
    label: "Laporan Akhir",
    roles: [roleTypeIds.DOSEN],
    sequence: 6,
  },
  {
    id: "laporan-akhir-penelitian-detail-dosen",
    path: "/penelitian/laporan-akhir/:id",
    component: LaporanAkhirDetail,
    roles: [
      roleTypeIds.DOSEN,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  {
    id: "laporan-akhir-penelitian-detail-dosen",
    path: "/penelitian/laporan-akhir/aktual/:id",
    component: LaporanAkhirDetailAktual,
    roles: [
      roleTypeIds.DOSEN,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  {
    id: "laporan-akhir-pengabdian-dosen",
    path: "/pengabdian/laporan-akhir",
    component: LaporanAkhirPengabdian,
    parent: "pengabdian-dosen",
    label: "Laporan Akhir",
    sequence: 5,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "laporan-akhir-pengabdian-detail-dosen",
    path: "/pengabdian/laporan-akhir/:id",
    component: LaporanAkhirPengabdianDetail,
    roles: [
      roleTypeIds.DOSEN,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  {
    id: "laporan-akhir-pengabdian-detail-dosen",
    path: "/pengabdian/laporan-akhir/aktual/:id",
    component: LaporanAkhirPengabdianDetailAktual,
    roles: [
      roleTypeIds.DOSEN,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  // Dosen - Catatan Harian
  {
    id: "catatan-harian-dosen",
    path: "/:jenis_usulan/pelaksanaan-kegiatan/catatan-harian",
    component: CatatanHarian,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "catatan-harian-pengabdian",
    label: "Catatan Harian",
    parent: "pengabdian-dosen",
    path: "/pengabdian/pelaksanaan-kegiatan/catatan-harian",
    roles: [roleTypeIds.DOSEN],
    hide: true,
    sequence: 6,
  },
  {
    id: "catatan-harian-penelitian",
    label: "Catatan Harian",
    parent: "penelitian-dosen",
    path: "/penelitian/pelaksanaan-kegiatan/catatan-harian",
    roles: [roleTypeIds.DOSEN],
    hide: true,
    sequence: 7,
  },
  {
    id: "catatan-harian-detail-dosen",
    path: "/:jenis_usulan/pelaksanaan-kegiatan/catatan-harian/:id",
    component: CatatanHarianDetail,
    roles: [roleTypeIds.DOSEN],
  },
  //Catatan Harian Prototipe/Kosabangsa
  {
    id: "dosen-catatan-harian-prototipe",
    label: "Catatan Harian",
    path: "/prototipe/catatan-harian",
    parent: "prototipe-dosen",
    roles: [roleTypeIds.DOSEN],
    sequence: 7,
  },
  {
    id: "dosen-catatan-harian-kosabangsa",
    label: "Catatan Harian",
    path: "/kosabangsa/catatan-harian",
    parent: "kosabangsa-dosen",
    roles: [roleTypeIds.DOSEN],
    sequence: 7,
  },
  {
    id: "dosen-catatan-harian-v2",
    path: "/:jenis_usulan/catatan-harian",
    component: CatatanHarianV2,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "dosen-catatan-harian-v2-detail",
    path: "/:jenis_usulan/catatan-harian/:id",
    component: CatatanHarianV2Detail,
    roles: [roleTypeIds.DOSEN],
  },
  // Dosen - Penugasan
  {
    id: "penugasan",
    label: "Program Lainnya",
    direktorat: [direktoratTypes.DIKTI],
    sequence: 6,
    icon: "bx bxs-file me",
    roles: [roleTypeIds.DOSEN],
    hide: true,
  },
  {
    id: "penugasan-nusantara",
    label: "Call for Proposal Nusantara",
    parent: "penugasan",
    direktorat: [direktoratTypes.DIKTI],
    path: "/penugasan/nusantara",
    component: PenugasanNusantara,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penugasan-nusantara-detail",
    path: "/penugasan/nusantara/:id",
    component: PenugasanNusantara,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penugasan-prototipe",
    label: "Call for Proposal Prototipe",
    parent: "penugasan",
    direktorat: [direktoratTypes.DIKTI],
    hide: true,
    path: "/penugasan/prototipe",
    component: PenugasanPrototipe,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penugasan-prototipe-detail",
    path: "/penugasan/prototipe/:id",
    component: PenugasanPrototipe,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penugasan-jfs",
    label: "Call for Proposal JFS SEA-EU",
    parent: "penugasan",
    direktorat: [direktoratTypes.DIKTI],
    hide: true,
    path: "/penugasan/jfs-sea-eu",
    component: PenugasanJfsSeaEu,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penugasan-jfs-detail",
    hide: true,
    path: "/penugasan/jfs-sea-eu/:id",
    component: PenugasanJfsSeaEu,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penugasan-google-bangkit",
    label: "Proposal Penugasan (Google Bangkit)",
    parent: "penugasan",
    direktorat: [direktoratTypes.DIKTI],
    hide: true,
    path: "/penugasan/google-bangkit",
    component: PenugasanGoogleBangkit,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penugasan-google-bangkit-detail",
    path: "/penugasan/google-bangkit/:id",
    component: PenugasanGoogleBangkit,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penugasan-modul-pembelajaran",
    label: "Proposal Penugasan (Modul Pembelajaran)",
    parent: "penugasan",
    direktorat: [direktoratTypes.DIKTI],
    hide: true,
    path: "/penugasan/modul-pembelajaran",
    component: PenugasanModulPembelajaran,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penugasan-modul-pembelajaran-detail",
    path: "/penugasan/modul-pembelajaran/:id",
    component: PenugasanModulPembelajaran,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penugasan-kosabangsa",
    label: "Proposal Penugasan Kosabangsa",
    parent: "penugasan",
    direktorat: [direktoratTypes.DIKTI],
    hide: true,
    path: "/penugasan/kosabangsa",
    component: PenugasanKosaBangsa,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "penugasan-kosabangsa-detail",
    path: "/penugasan/kosabangsa/:id",
    component: PenugasanKosaBangsa,
    parent: "penugasan",
    direktorat: [direktoratTypes.DIKTI],
    roles: [roleTypeIds.DOSEN],
  },

  {
    id: "pendfataran-reviewer",
    label: "Pendaftaran Reviewer Vokasi",
    sequence: 8,
    path: "/reviewer-dosen/pendaftaran",
    component: PendaftaranReviewer,
    roles: [roleTypeIds.DOSEN],
    hide: hideMenu(),
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
    icon: "bx bxs-file me",
  },
  {
    id: "pendaftaran-reviewer-detail",
    path: "/reviewer-dosen/pendaftaran/:id",
    component: PendaftaranReviewer,
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
    roles: [roleTypeIds.DOSEN],
  },

  // Personel Invitation
  {
    id: "personel-invitation-detail",
    path: "/penelitian/personel-invitation/:id",
    component: PersonelInvitationPenelitian,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "personel-invitation-pengabdian-detail",
    path: "/pengabdian/personel-invitation/:id",
    component: PersonelInvitationPengabdian,
    roles: [roleTypeIds.DOSEN],
  },

  //Kosabangsa Pendamping
  {
    id: "kosabangsa-pendamping",
    label: "Pendaftaran Pendamping",
    parent: "kosabangsa-dosen",
    path: "/kosabangsa/pendamping",
    component: PendampingKosabangsa,
    roles: [roleTypeIds.DOSEN],
    sequence: 1,
    // hide: process.env.REACT_APP_NODE === "production",
    hide: true,
  },
  {
    id: "kosabangsa-pendamping-detail",
    path: "/kosabangsa/pendamping/detail/:id",
    component: PendampingKosabangsaDetail,
    roles: [roleTypeIds.DOSEN, roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "kosabangsa-pendamping-form",
    path: "/kosabangsa/pendamping/form/:id?",
    component: PendampingKosabangsaForm,
    roles: [roleTypeIds.DOSEN],
  },
  // Kosabangsa Pelaksana
  {
    id: "pelaksana-kosabangsa",
    parent: "kosabangsa-dosen",
    path: "/kosabangsa/pelaksana",
    label: "Pendaftaran Pelaksana",
    component: PelaksanaKosabangsa,
    sequence: 2,
    hide: true,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "pelaksana-kosabangsa-create",
    path: "/kosabangsa/pelaksana/form",
    component: PelaksanaKosabangsaForm,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "pelaksana-kosabangsa-edit",
    path: "/kosabangsa/pelaksana/form/:id",
    component: PelaksanaKosabangsaForm,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "pelaksana-kosabangsa-view-detail",
    path: "/kosabangsa/pelaksana/detail/:id",
    component: PelaksanaKosabangsaDetail,
    roles: [roleTypeIds.DOSEN],
  },
  // Personel Invitation Kosabangsa
  {
    id: "personel-invitation-kosabangsa-pendamping-detail",
    path: "/kosabangsa/pendamping/personel-invitation/:id",
    component: PersonelInvitationKosabangsaPendamping,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "personel-invitation-kosabangsa-pelaksana-detail",
    path: "/kosabangsa/pelaksana/personel-invitation/:id",
    component: PersonelInvitationKosabangsaPelaksana,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "personel-invitation-kosabangsa-kolaborasi-detail",
    path: "/kosabangsa-kolaborasi/personel-invitation/:id",
    component: PersonelInvitationKosabangsaKolaborasi,
    roles: [roleTypeIds.DOSEN],
  },
  //Usulan Kosabangsa
  {
    id: "usulan-kosabangsa-list",
    parent: "kosabangsa-dosen",
    path: "/kosabangsa/usulan",
    label: "Usulan Kolaborasi",
    component: UsulanKosabangsa,
    sequence: 3,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-kosabangsa",
    parent: "kosabangsa-dosen",
    path: "/kosabangsa/usulan/form",
    component: FormUsulanKosabangsa,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-kosabangsa-v2024",
    parent: "kosabangsa-dosen",
    path: MENU_URL.FORM_KOSABANGSA_V2024,
    component: FormUsulanKosabangsaV2024,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-kosabangsa-v2024-update",
    parent: "kosabangsa-dosen",
    path: `${MENU_URL.FORM_KOSABANGSA_V2024}/:id`,
    component: FormUsulanKosabangsaV2024,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-kosabangsa-detail",
    parent: "kosabangsa-dosen",
    path: "/kosabangsa/usulan/detail/:id",
    component: KosaBangsaDetail,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "usulan-kosabangsa-update",
    parent: "kosabangsa-dosen",
    path: "/kosabangsa/usulan/form/:id",
    component: FormUsulanKosabangsa,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "kosabangsa-dosen-usulan-perbaikan",
    label: "Usulan Perbaikan",
    path: "/usulan-revisi/kosabangsa",
    parent: "kosabangsa-dosen",
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "proposal-laporan-akhir-kosabangsa",
    parent: "kosabangsa-dosen",
    label: "Laporan Akhir",
    path: "/kosabangsa/laporan-akhir-proposal",
    roles: [roleTypeIds.DOSEN],
    sequence: 6,
  },
  //Usulan Prototipe
  {
    id: "prototipe-dosen-usulan",
    label: "Usulan Prototipe",
    path: "/prototipe/usulan",
    parent: "prototipe-dosen",
    component: PrototipeList,
    roles: [roleTypeIds.DOSEN],
    sequence: 1,
  },

  {
    id: "prototipe-dosen-usulan",
    path: "/prototipe/usulan/form",
    component: PrototipeForm,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "prototipe-dosen-usulan-2",
    path: "/prototipe/usulan/form/:id",
    component: PrototipeForm,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "prototipe-dosen-detail",
    path: "/prototipe/usulan/detail/:id",
    component: PorototipeUsulanDetail,
    // hide: process.env.REACT_APP_NODE === "production",
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "personel-invitation-usulan-prototipe-detail",
    path: "/prototipe/usulan/personel-invitation/:id",
    component: PrototipePersonelInvitation,
    roles: [roleTypeIds.DOSEN],
  },

  // Laporan Kemajuan V2
  {
    id: "laporan-kemajuan-prototipe",
    parent: "prototipe-dosen",
    label: "Laporan Kemajuan",
    path: "/prototipe/laporan-kemajuan-proposal",
    roles: [roleTypeIds.DOSEN],
    component: LaporanKemajuanPrototipeV2,
    sequence: 3,
  },
  {
    id: "laporan-kemajuan-prototipe-detail",
    path: "/prototipe/laporan-kemajuan-proposal/:id",
    roles: [roleTypeIds.DOSEN],
    component: LaporanKemajuanPrototipeDetailV2,
  },
  {
    id: "laporan-kemajuan-kosabangsa",
    parent: "kosabangsa-dosen",
    label: "Laporan Kemajuan",
    path: "/kosabangsa/laporan-kemajuan-proposal",
    roles: [roleTypeIds.DOSEN],
    sequence: 5,
    component: LaporanKemajuanKosabangsaV2,
  },
  {
    id: "laporan-kemajuan-kosabangsa-detail",
    path: "/kosabangsa/laporan-kemajuan-proposal/:id",
    roles: [roleTypeIds.DOSEN],
    component: LaporanKemajuanKosabangsaDetailV2,
  },
  //Laporan Akhir Proposal V2
  {
    id: "proposal-laporan-akhir-prototipe",
    parent: "prototipe-dosen",
    label: "Laporan Akhir",
    sequence: 4,
    path: "/prototipe/laporan-akhir-proposal",
    component: LaporanAkhirPrototipeV2,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "laporan-akhir-prototipe-detail",
    path: "/prototipe/laporan-akhir-proposal/:id",
    roles: [roleTypeIds.DOSEN],
    component: LaporanAkhirPrototipeV2DetailV2,
  },
  {
    id: "proposal-laporan-akhir-kosabangsa",
    parent: "kosabangsa-dosen",
    label: "Laporan Akhir",
    sequence: 4,
    path: "/kosabangsa/laporan-akhir-proposal",
    component: LaporanAkhirKosabangsaV2,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "laporan-akhir-kosabangsa-detail",
    path: "/kosabangsa/laporan-akhir-proposal/:id",
    roles: [roleTypeIds.DOSEN],
    component: LaporanAkhirKosabangsaV2DetailV2,
  },

  //BIMTEK PROPOSAL
  {
    id: "proposal-bimtek",
    path: "/:kegiatan/bimtek-proposal",
    component: ListBimtekProposal,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "proposal-bimtek-penelitian",
    parent: "penelitian-dosen",
    label: "Bimtek",
    sequence: 8,
    path: "/penelitian/bimtek-proposal",
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "proposal-bimtek-pengabdian",
    parent: "pengabdian-dosen",
    label: "Bimtek",
    sequence: 8,
    path: "/pengabdian/bimtek-proposal",
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "proposal-bimtek-penelitian-edit",
    path: "/penelitian/bimtek-proposal/edit/:id",
    component: FormBimtekProposalPenelitian,
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "proposal-bimtek-pengabdian-edit",
    path: "/pengabdian/bimtek-proposal/edit/:id",
    component: FormBimtekProposalPengabdian,
    roles: [roleTypeIds.DOSEN],
  },
  //END BIMTEK PROPOSAL
  //KEKAYAAN INTELEKTUAL
  {
    id: "kekayaan-intelektual",
    label: "Kekayaan Intelektual",
    icon: "bx bx-collection",
    sequence: 10,
    component: ListPendaftaranKI,
    direktorat: [direktoratTypes.DIKTI],
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "kekayaan-intelektual-pendaftaran",
    path: "/kekayaan-intelektual/pendaftaran/:jenis",
    component: ListPendaftaranKI,
    direktorat: [direktoratTypes.DIKTI],
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "kekayaan-intelektual-pendaftaran-input",
    path: "/kekayaan-intelektual/pendaftaran/:jenis/input/:id?",
    component: InputPendaftaranKI,
    roles: [roleTypeIds.DOSEN, roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "kekayaan-intelektual-pendaftaran-pdpp",
    label: "Pendaftaran Pelatihan PDPP",
    parent: "kekayaan-intelektual",
    path: "/kekayaan-intelektual/pendaftaran/pelatihan-pdpp",
    direktorat: [direktoratTypes.DIKTI],
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "kekayaan-intelektual-pendaftaran-uber-ki",
    label: "Proposal Uber KI",
    parent: "kekayaan-intelektual",
    path: "/kekayaan-intelektual/pendaftaran/uber-ki",
    direktorat: [direktoratTypes.DIKTI],
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "kekayaan-intelektual-workshop-sentra-ki",
    label: "Pendaftaran Workshop Sentra KI",
    parent: "kekayaan-intelektual",
    path: "/kekayaan-intelektual/pendaftaran/workshop-sentra-ki",
    direktorat: [direktoratTypes.DIKTI],
    roles: [roleTypeIds.DOSEN],
  },

  {
    id: "kekayaan-intelektual-pendaftaran-pdpp-input",
    path: "/kekayaan-intelektual/pendaftaran/pelatihan-pdpp/input/:id?",
    direktorat: [direktoratTypes.DIKTI],
    roles: [roleTypeIds.DOSEN, roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "kekayaan-intelektual-pendaftaran-uber-ki-input",
    path: "/kekayaan-intelektual/pendaftaran/uber-ki/input/:id?",
    direktorat: [direktoratTypes.DIKTI],
    roles: [roleTypeIds.DOSEN, roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "kekayaan-intelektual-pendaftaran-workshop-sentra-ki-input",
    path: "/kekayaan-intelektual/pendaftaran/workshop-sentra-ki/input/:id?",
    direktorat: [direktoratTypes.DIKTI],
    roles: [roleTypeIds.DOSEN, roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "reviewer-ki-administrasi-2",
    path: "/reviewer-ki/adm",
    parent: "kekayaan-intelektual",
    label: "Penilaian Administrasi",
    component: ReviewerKIDashAdm,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_KI_NON_DOSEN,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  {
    id: "reviewer-ki-bimtek-2",
    path: "/reviewer-ki/bimtek",
    parent: "kekayaan-intelektual",
    label: "Penilaian Bimtek",
    component: ReviewerKIDashBimtek,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_KI_NON_DOSEN,
      roleTypeIds.REVIEWER_PT,
    ],
  },

  // E-Catalog
  {
    id: "e-catalog-dosen",
    label: "E-Catalog",
    sequence: 10,
    icon: "bx bx-layer",
    roles: [roleTypeIds.DOSEN],
  },
  {
    id: "e-catalog-pencarian",
    parent: "e-catalog-dosen",
    label: "Pencarian",
    path: "/e-catalog/search",
    component: SearchEKatalog,
    roles: [roleTypeIds.DOSEN],
  },
];
