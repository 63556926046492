/* eslint-disable no-unused-vars */
import utilities from "helpers/utilities";
import { instances, instancesV2 } from "../api_helper";
import roleTypeIds from "constants/role-type-ids.js";
export const searchProposalEKatalog = payload => {
  const roles = utilities.getAuthUser()?.data?.roles || [];
  if (roles.includes(roleTypeIds.DOSEN)) {
    payload.queryParams.is_dosen = 1;
  }
  // berlaku untuk penelitian v1,v2 dan pengabdian v1,v2 (menggunakan query param jenis_kegiatan)
  return instances.request(
    `/ecatalog/penelitian/searchV2?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getDetailProposalEKatalog = payload => {
  // berlaku untuk penelitian v1,v2 dan pengabdian v1,v2 (tidak menggunakan query param jenis_kegiatan)
  return instances.request(`/ecatalog/penelitian/${payload.path.id}`, {
    method: "GET",
  });
};
export const searchPerguruanTinggiEKatalog = payload => {
  return instances.request(
    `/ecatalog/perguruantinggi/search?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
const fetchWithErrorHandling = async (url, options) => {
  try {
    const response = await instances.request(url, options);
    return response;
  } catch (error) {
    return { error }; // Return an object with the error
  }
};
export const getDetailChartPerguruanTinggiEKatalog = async payload => {
  const [
    totalPpmPerYear,
    totalPpmPerBidangFokus,
    totalDanaPpmPerYear,
    totalDanaPpmPerBidangFokus,
    dataRiset,
  ] = await Promise.all([
    fetchWithErrorHandling(
      `/ecatalog/perguruantinggi/totalppmperyear/${
        payload.path.id_institusi
      }?${new URLSearchParams(
        utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
      ).toString()}`,
      { method: "GET" }
    ),
    fetchWithErrorHandling(
      `/ecatalog/perguruantinggi/totalppmperbidangfokus/${
        payload.path.id_institusi
      }?${new URLSearchParams(
        utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
      ).toString()}`,
      { method: "GET" }
    ),
    fetchWithErrorHandling(
      `/ecatalog/perguruantinggi/totaldanappmperyear/${
        payload.path.id_institusi
      }?${new URLSearchParams(
        utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
      ).toString()}`,
      { method: "GET" }
    ),
    fetchWithErrorHandling(
      `/ecatalog/perguruantinggi/totaldanappmperbidangfokuschart/${
        payload.path.id_institusi
      }?${new URLSearchParams(
        utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
      ).toString()}`,
      { method: "GET" }
    ),
    fetchWithErrorHandling(
      `/ecatalog/benchmark/comparept/riset?${new URLSearchParams(
        utilities.removeNullOrUndefinedProperties(
          payload?.queryParamsDataRiset || {}
        )
      ).toString()}`,
      { method: "GET" }
    ),
  ]);
  return {
    totalPpmPerYear: totalPpmPerYear.data,
    totalPpmPerBidangFokus: totalPpmPerBidangFokus.data,
    totalDanaPpmPerYear: totalDanaPpmPerYear.data,
    totalDanaPpmPerBidangFokus: totalDanaPpmPerBidangFokus.data,
    dataRiset: dataRiset.data,
  };
};
export const getPpmPerBidangFokusEKatalog = payload => {
  return instances.request(
    `/ecatalog/perguruantinggi/totaldanappmperbidangfokus/${
      payload.path.id_institusi
    }?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getPpmPerYearEKatalog = payload => {
  return instances.request(
    `/ecatalog/perguruantinggi/totaldanappmperyearlist/${
      payload.path.id_institusi
    }?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getListPpmPerguruanTinggiEKatalog = payload => {
  return instances.request(
    `/ecatalog/perguruantinggi/listppm/${
      payload.path.id_institusi
    }?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};

export const crudKeywordManagementEKatalog = async payload => {
  if (payload.method === "GET") {
    const nomor_keyword = payload.options.nomor_keyword
      ? payload.options.nomor_keyword
      : 1;
    if (payload.options.type === "get-all") {
      return await instances.request(
        `/ecatalog/keyword${nomor_keyword}?${new URLSearchParams(
          utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
        ).toString()}`,
        {
          method: "GET",
        }
      );
    } else if (payload.options.type === "get-by-id") {
      return await instances.request(
        `/ecatalog/keyword${nomor_keyword}/${payload.path.id}`,
        {
          method: "GET",
        }
      );
    }
  } else if (payload.method === "POST") {
    const nomor_keyword = payload.options.nomor_keyword
      ? payload.options.nomor_keyword
      : 1;
    if (payload.options.type === "create") {
      return await instances.request(`/ecatalog/keyword${nomor_keyword}`, {
        method: "POST",
        data: payload.data,
      });
    }
  } else if (payload.method === "PUT") {
    const nomor_keyword = payload.options.nomor_keyword
      ? payload.options.nomor_keyword
      : 1;
    if (payload.options.type === "update") {
      return await instances.request(
        `/ecatalog/keyword${nomor_keyword}/${payload.path.id}`,
        {
          method: "PUT",
          data: payload.data,
        }
      );
    }
  } else if (payload.method === "DELETE") {
    const nomor_keyword = payload.options.nomor_keyword
      ? payload.options.nomor_keyword
      : 1;
    if (payload.options.type === "delete") {
      return await instances.request(
        `/ecatalog/keyword${nomor_keyword}/${payload.path.id}`,
        {
          method: "DELETE",
          data: payload.data,
        }
      );
    }
  }
};
export const getListJabatanDosenEKatalog = payload => {
  return instances.request(
    `/ecatalog/perguruantinggi/jumlahdosen/${
      payload.path.id_institusi
    }?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getListDosenEKatalog = payload => {
  return instances.request(
    `/ecatalog/perguruantinggi/listdosen/${
      payload.path.id_institusi
    }?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getListJumlahLuaranEKatalog = payload => {
  return instances.request(
    `/ecatalog/perguruantinggi/jumlahluaran/${
      payload.path.id_institusi
    }?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getListBidangFokusECatalog = payload => {
  return instances.request(`/ecatalog/bidangfokus/list`, {
    method: "GET",
  });
};
export const getDanaPpmBidangFokusECatalog = payload => {
  return instances.request(
    `/ecatalog/bidangfokus/totalppm/${payload.path.id_bidang_fokus}`,
    {
      method: "GET",
    }
  );
};
export const getTotalKlasterECatalog = payload => {
  return instances.request(`/ecatalog/klasterpt/list`, {
    method: "GET",
  });
};
export const getListPtByKlasterECatalog = payload => {
  return instances.request(
    `/ecatalog/klasterpt/listpt/${
      payload.path.kd_klaster
    }?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getListBenchmarkPpmECatalog = payload => {
  return instances.request(
    `/ecatalog/benchmark/ppm?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getCompareBenchmarkPtEcatalog = payload => {
  let URL = ``;
  if (payload.options.type === "jumlah_ppm") {
    URL = `/ecatalog/benchmark/comparept/ppm?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`;
  } else if (payload.options.type === "jumlah_pendanaan") {
    URL = `/ecatalog/benchmark/comparept/pendanaan?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`;
  } else if (payload.options.type === "sitasi_hki") {
    URL = `/ecatalog/benchmark/comparept/sitasihki?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`;
  } else if (payload.options.type === "tkt") {
    URL = `/ecatalog/benchmark/comparept/tkt?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`;
  } else if (payload.options.type === "dosen") {
    URL = `/ecatalog/benchmark/comparept/dosen?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`;
  } else if (payload.options.type === "jumlah_publikasi") {
    URL = `/ecatalog/benchmark/comparept/publikasi?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`;
  } else if (payload.options.type === "jumlah_riset") {
    URL = `/ecatalog/benchmark/comparept/riset?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};
export const getDropdownPerguruanTinggiECatalog = payload => {
  return instances.request(
    `/ecatalog/perguruantinggi/search/dropdown?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getLldiktiMapsECatalog = payload => {
  return instances.request(
    `/ecatalog/penelitian/lldiktimaps?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getDetailProposalForPdfECatalog = payload => {
  if (payload.options.type === "proposal_v2") {
    // proposal v2 penelitian and pengabdian
    return instancesV2.request(`/proposal/${payload.path.id}`, {
      method: "GET",
    });
  } else if (payload.options.type === "proposal_v1") {
    // proposal v1 penelitian and pengabdian
    if (payload.options.kd_jenis_kegiatan === "1") {
      return instances.request(`/proposal/penelitian/${payload.path.id}`, {
        method: "GET",
      });
    } else {
      return instances.request(`/proposal/pengabdian/${payload.path.id}`, {
        method: "GET",
      });
    }
  }
};
