/* eslint-disable no-unused-vars */

import React from "react";
import encStorage from "helpers/encStorage";
import roleTypeIds from "constants/role-type-ids.js";

const storage = JSON.parse(encStorage.getItem("authUser"));
const institusi_klaster = storage?.data?.institusi_klaster;

const hideMenu = () => {
  // return false;
  // Comment, sewaktu-waktu berubah
  // if (storage?.data?.status_level?.includes("Vokasi")) {
  //   //vokasi
  //   return false;
  // } else {
  //   // dikti
  //   if (institusi_klaster === "D" || institusi_klaster === "J") {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
  if (institusi_klaster === "D" || institusi_klaster === "J") {
    return false;
  } else {
    return true;
  }
};
const CreatePengumuman = React.lazy(() =>
  import("../pages/LandingPage/CreatePengumuman.js")
);

const AdminAnnouncement = React.lazy(() =>
  import("../pages/LandingPage/AdminAnnouncement.js")
);
const EditPengumuman = React.lazy(() =>
  import("../components/getPengumuman/EditPengumuman")
);
const CreateBerita = React.lazy(() =>
  import("../pages/LandingPage/CreateBerita.js")
);
const AdminBerita = React.lazy(() =>
  import("../pages/LandingPage/AdminBerita.js")
);
const EditBerita = React.lazy(() => import("../components/Berita/EditBerita"));
const AdminPosterPengumuman = React.lazy(() => import("../pages/LandingPage/AdminPosterPengumuman.js"));

//demo dashboard operator pt
const DashboardOperator = React.lazy(() =>
  import("../pages/Operator/Dashboard/index")
);
const DashboardOperatorPusat = React.lazy(() =>
  import("../pages/OperatorPusat/Dashboard/index")
);
const DashboardOperatorAdminPrototipe = React.lazy(() =>
  import("../pages/OperatorPrototipe/Dashboard/index")
);
// Operator
//demo dashboard operator monitoring
// operator pt monitoring usulan baru
const OperatorMonitoringUsulanBaruDetail = React.lazy(() =>
  import("../pages/Operator/Monitoring/UsulanBaru/UsulanBaru")
);
const OperatorMonitoringUsulanBaruUsulanDikirimDetail = React.lazy(() =>
  import("../pages/Operator/Monitoring/UsulanBaru/UsulanDikirim")
);
const OperatorMonitoringUsulanBaruUsulanBelumDitinjauDetail = React.lazy(() =>
  import("../pages/Operator/Monitoring/UsulanBaru/UsulanBelumDitinjau")
);
const OperatorMonitoringUsulanBaruUsulanDisetujuiDetail = React.lazy(() =>
  import("../pages/Operator/Monitoring/UsulanBaru/UsulanDisetujui")
);
const OperatorMonitoringUsulanBaruUsulanDitolakDetail = React.lazy(() =>
  import("../pages/Operator/Monitoring/UsulanBaru/UsulanDitolak")
);
const OperatorMonitoringUsulanBaruUsulanDireview = React.lazy(() =>
  import("../pages/Operator/Monitoring/UsulanBaru/UsulanDireview")
);

const OperatorMonitoringPerbaikanUsulan = React.lazy(() =>
  import("../pages/Operator/Monitoring/PerbaikanUsulan/index")
);
const OperatorMonitoringUsulanLanjutan = React.lazy(() =>
  import("../pages/Operator/Monitoring/UsulanLanjutan/index")
);
const OperatorMonitoringSptjb = React.lazy(() =>
  import("../pages/Operator/Monitoring/SPTJB/index")
);
const OperatorMonitoringCatatanHarian = React.lazy(() =>
  import("../pages/Operator/Monitoring/CatatanHarian/index")
);
const OperatorMonitoringCatatanHarianDetailSatu = React.lazy(() =>
  import("../pages/Operator/Monitoring/CatatanHarian/Detail/DetailSatu")
);
const OperatorMonitoringCatatanHarianDetailDua = React.lazy(() =>
  import("../pages/Operator/Monitoring/CatatanHarian/Detail/DetailDua")
);
const OperatorMonitoringCatatanHarianDetailTiga = React.lazy(() =>
  import("../pages/Operator/Monitoring/CatatanHarian/Detail/DetailTiga")
);
const OperatorMonitoringLaporanKemajuan = React.lazy(() =>
  import("../pages/Operator/Monitoring/LaporanKemajuan/index")
);
const OperatorMonitoringLaporanKemajuanDetail = React.lazy(() =>
  import("../pages/Operator/Monitoring/LaporanKemajuan/Detail")
);
const OperatorMonitoringLaporanAkhir = React.lazy(() =>
  import("../pages/Operator/Monitoring/LaporanAkhir/index")
);
const OperatorMonitoringLaporanAkhirDetail = React.lazy(() =>
  import("../pages/Operator/Monitoring/LaporanAkhir/Detail")
);
const OperatorMonitoringMonev = React.lazy(() =>
  import("../pages/Operator/Monitoring/Monev/index.js")
);
const OperatorDetailMonitoringMonev = React.lazy(() =>
  import("../pages/Operator/Monitoring/Monev/Detail.js")
);

// Operator Pusat Monitoring

// operator pusat monitoring usulan baru
const OperatorPusatMonitoringUsulanBaru = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/UsulanBaru/index")
);
const OperatorPusatMonitoringUsulanBaruDetail = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/UsulanBaru/UsulanBaru")
);
const OperatorPusatMonitoringUsulanBaruUsulanDikirimDetail = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/UsulanBaru/UsulanDikirim")
);
const OperatorPusatMonitoringUsulanBaruUsulanBelumDitinjauDetail = React.lazy(
  () =>
    import("../pages/OperatorPusat/Monitoring/UsulanBaru/UsulanBelumDitinjau")
);
const OperatorPusatMonitoringUsulanBaruUsulanDisetujuiDetail = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/UsulanBaru/UsulanDisetujui")
);
const OperatorPusatMonitoringUsulanBaruUsulanDitolakDetail = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/UsulanBaru/UsulanDitolak")
);

const OperatorPusatMonitoringPerbaikanUsulan = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/PerbaikanUsulan/index")
);
const OperatorPusatMonitoringUsulanLanjutan = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/UsulanLanjutan/index")
);
const OperatorPusatMonitoringCatatanHarian = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/CatatanHarian/index")
);
const OperatorPusatMonitoringCatatanHarianDetailSatu = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/CatatanHarian/Detail/DetailSatu")
);
const OperatorPusatMonitoringCatatanHarianDetailDua = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/CatatanHarian/Detail/DetailDua")
);
const OperatorPusatMonitoringCatatanHarianDetailTiga = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/CatatanHarian/Detail/DetailTiga")
);
const OperatorPusatMonitoringLaporanKemajuan = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/LaporanKemajuan/index")
);
const OperatorPusatMonitoringLaporanKemajuanDetail = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/LaporanKemajuan/Detail")
);
const OperatorPusatMonitoringLaporanAkhir = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/LaporanAkhir/index")
);
const OperatorPusatMonitoringLaporanAkhirDetail = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/LaporanAkhir/Detail")
);

// Operator  - PT Data Pendukung
const CariAkunDosen = React.lazy(() =>
  import("../pages/Operator/DataPendukung/PencarianAkunDosen/index")
);
const SinkronisasiDosen = React.lazy(() =>
  import("../pages/Operator/DataPendukung/SinkronisasiDosen/index")
);
const ProfilLembaga = React.lazy(() =>
  import("../pages/Operator/DataPendukung/ProfilLembaga/index")
);
const SinkronisasiProdi = React.lazy(() =>
  import("../pages/Operator/DataPendukung/SinkronisasiProdi/index")
);
const DetailDosen = React.lazy(() =>
  import("../pages/Operator/DataPendukung/SinkronisasiDosen/DetailDosen")
);
const EditDosen = React.lazy(() =>
  import("../pages/Operator/DataPendukung/SinkronisasiDosen/EditDosen")
);
const PersetujuanBaruOptPt = React.lazy(() =>
  import("../pages/Operator/Persetujuan/PersetujuanBaru/index.js")
);

// Operator PT Pengelolaan Reviewer
const SKRektorReviewer = React.lazy(() =>
  import("../pages/Operator/Reviewer/SKRektorReviewer")
);
const DaftarReviewer = React.lazy(() =>
  import("../pages/Operator/Reviewer/DaftarReviewer")
);
const DaftarReviewerV2 = React.lazy(() =>
  import("../pages/Operator/Reviewer/DaftarReviewerV2")
);
const DaftarReviewerEksternal = React.lazy(() =>
  import("../pages/Operator/Reviewer/DaftarReviewerEksternal")
);
const MonitoringPenilaian = React.lazy(() =>
  import("../pages/Operator/Reviewer/MonitoringPenilaian")
);
const MonitoringPenilaianDetail = React.lazy(() =>
  import("../pages/Operator/Reviewer/MonitoringPenilaian/Detail")
);
const PenugasanReviewer = React.lazy(() =>
  import("../pages/Operator/Reviewer/PenugasanReviewer")
);
const PenugasanReviewerDetail = React.lazy(() =>
  import("../pages/Operator/Reviewer/PenugasanReviewer/Detail")
);
const PenugasanReviewerInternal = React.lazy(() =>
  import("../pages/Operator/Reviewer/PenugasanReviewer/ReviewerInternal")
);
const PenugasanReviewerExternal = React.lazy(() =>
  import("../pages/Operator/Reviewer/PenugasanReviewer/ReviewerExternal")
);
const PenugasanBebanReviewer = React.lazy(() =>
  import("../pages/Operator/Reviewer/PenugasanReviewer/BebanReviewer")
);
const PlottingReviewer = React.lazy(() =>
  import("../pages/Operator/Reviewer/PlottingReviewer")
);
const PlottingReviewerDetail = React.lazy(() =>
  import("../pages/Operator/Reviewer/PlottingReviewer/Detail")
);
const PlottingBebanReviewer = React.lazy(() =>
  import("../pages/Operator/Reviewer/PlottingReviewer/BebanReviewer")
);
// Plotting Reveiwer PT v2
const PlottingReviewerV2 = React.lazy(() =>
  import("../pages/Operator/Reviewer/PlottingReviewerV2")
);
const PlottingReviewerDetailV2 = React.lazy(() =>
  import("../pages/Operator/Reviewer/PlottingReviewerV2/Detail")
);
// Operator Pusat Pengelolaan Reviewer
const OperatorPusatSKRektorReviewer = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/SKRektorReviewer")
);
const OperatorPusatDaftarReviewer = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/DaftarReviewer")
);
const OperatorPusatPenugasanReviewer = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/PenugasanReviewer")
);
const OperatorPusatPenugasanReviewerDetail = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/PenugasanReviewer/Detail")
);
const OperatorPusatPenugasanReviewerInternal = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/PenugasanReviewer/ReviewerInternal")
);
const OperatorPusatPenugasanReviewerExternal = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/PenugasanReviewer/ReviewerExternal")
);
const OperatorPusatPenugasanBebanReviewer = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/PenugasanReviewer/BebanReviewer")
);
const OperatorPusatPlottingReviewer = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/PlottingReviewer")
);
const OperatorPusatPlottingReviewerDetail = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/PlottingReviewer/Detail")
);
const OperatorPusatPlottingBebanReviewer = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/PlottingReviewer/BebanReviewer")
);
const OperatorPusatPlottingReviewerV2 = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/PlottingReviewerV2")
);
const OperatorPusatSearchPlottingReviewerV2 = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/SearchPlottingReviewerV2")
);
const OperatorPusatPlottingReviewerDetailV2 = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/PlottingReviewerV2/Detail")
);
const OperatorPusatPlottingBebanReviewerV2 = React.lazy(() =>
  import("../pages/OperatorPusat/Reviewer/PlottingReviewerV2/BebanReviewer")
);

// Operator Pusat Pengelolaan Kontak PIC
const KontakPIC = React.lazy(() => import("../pages/OperatorPusat/KontakPIC"));

// OP  - DIKTI PROPOSAL PENUGASAN
const PenugasanNusantara = React.lazy(() =>
  import("../pages/Penugasan/Nusantara")
);
const PenugasanPrototipe = React.lazy(() =>
  import("../pages/Penugasan/Prototipe")
);
const PenugasanJfsSeaEu = React.lazy(() => import("../pages/Penugasan/JFS"));
const PenugasanGoogleBangkit = React.lazy(() =>
  import("../pages/Penugasan/GoogleBangkit")
);
const PenugasanModulPembelajaran = React.lazy(() =>
  import("../pages/Penugasan/ModulPembelajaran")
);
const PenugasanKosaBangsa = React.lazy(() =>
  import("../pages/Penugasan/KosaBangsa")
);
//Test Component
// const TestComp = React.lazy(() => import("../components/TestComponent"));
// Change Password
const ChangePassword = React.lazy(() =>
  import("../pages/Authentication/ChangePassword.js")
);

// * Test Generate Pdf
const GeneratePdfTest = React.lazy(() =>
  import("../pages/Operator/Monitoring/GeneratePdfTest")
);

// Operator - LLDIKTI START
const DashboardLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Dashboard")
);
const OperatorLLDIKTIMonitoringLaporanKemajuan = React.lazy(() => {
  return import("../pages/OperatorLLDIKTI/Monitoring/LaporanKemajuan/index.js");
});
const OperatorLLDIKTIMonitoringLaporanDetail = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Monitoring/LaporanKemajuan/Detail.js")
);
const OperatorLLDIKTIMonitoringPerbaikanUsulan = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Monitoring/PerbaikanUsulan/index.js")
);
const OperatorLLDIKTIMonitoringCatatanHarian = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Monitoring/CatatanHarian/index.js")
);
const OperatorLLDIKTIMonitoringCatatanHarianDetailSatu = React.lazy(() =>
  import(
    "../pages/OperatorLLDIKTI/Monitoring/CatatanHarian/Detail/DetailSatu.js"
  )
);
const OperatorLLDIKTIMonitoringCatatanHarianDetailDua = React.lazy(() =>
  import(
    "../pages/OperatorLLDIKTI/Monitoring/CatatanHarian/Detail/DetailDua.js"
  )
);
const OperatorLLDIKTIMonitoringCatatanHarianDetailTiga = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Monitoring/CatatanHarian/Detail/DetailTiga")
);
const OperatorLLDIKTIMonitoringLaporanAkhir = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Monitoring/LaporanAkhir/index")
);
const OperatorLLDIKTIMonitoringLaporanAkhirDetail = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Monitoring/LaporanAkhir/Detail.js")
);
const KonfigurasiDosenLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Konfigurasi/KonfigurasiDosen/index.js")
);
const KonfigurasiDetailDosenLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Konfigurasi/KonfigurasiDosen/DetailDosen.js")
);
const KonfigurasiEditDosenLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Konfigurasi/KonfigurasiDosen/EditDosen.js")
);
const KonfigurasPerguruanTinggiLLDIKTI = React.lazy(() =>
  import(
    "../pages/OperatorLLDIKTI/Konfigurasi/KonfigurasiPerguruanTinggi/index.js"
  )
);
const KonfigurasiProdiLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Konfigurasi/KonfigurasiProdi/index.js")
);
const KonfigurasiProfilLembagaLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Konfigurasi/KonfigurasiLembaga/index.js")
);
const PersetujuanBaruLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Persetujuan/PersetujuanBaru/index.js")
);
const UsulanBaruLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Persetujuan/PersetujuanBaru/UsulanBaru")
);
const UsulanDikirimLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Persetujuan/PersetujuanBaru/UsulanDikirm")
);
const UsulanBelumDitinjauLLDIKTI = React.lazy(() =>
  import(
    "../pages/OperatorLLDIKTI/Persetujuan/PersetujuanBaru/UsulanBelumDitinjau"
  )
);
const UsulanDisetujuiLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Persetujuan/PersetujuanBaru/UsulanDisetujui")
);
const UsulanDitolakLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Persetujuan/PersetujuanBaru/UsulanDitolak")
);
const MonitoringMonevLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Monitoring/Monev/index.js")
);
const MonitoringDetailMonevLLDIKTI = React.lazy(() =>
  import("../pages/OperatorLLDIKTI/Monitoring/Monev/Detail.js")
);

// Operator - LLDIKTI END

// Kepala Lembaga
const DashboardKepalaLembaga = React.lazy(() =>
  import("../pages/OperatorLembaga/Dashboard/index.js")
);
const DashboardMonitoringUsulan = React.lazy(() =>
  import("../pages/OperatorLembaga/Persetujuan/PersetujuanBaru/index.js")
);

const PersetujuanBaruPrototipeKepalaLembaga = React.lazy(() =>
  import("../pages/OperatorLembaga/Persetujuan/PersetujuanPrototipe")
);
const OperatorUsulanDisetujui = React.lazy(() =>
  import(
    "../pages/OperatorLembaga/Persetujuan/PersetujuanBaru/UsulanDisetujui.js"
  )
);
const OperatorUsulanPrototipeList = React.lazy(() =>
  import("../pages/OperatorLembaga/Persetujuan/PersetujuanPrototipe/ListUsulan")
);
const OperatorUsulanDitolak = React.lazy(() =>
  import(
    "../pages/OperatorLembaga/Persetujuan/PersetujuanBaru/UsulanDitolak.js"
  )
);
const OperatorUsulanUsulanBaru = React.lazy(() =>
  import("../pages/OperatorLembaga/Persetujuan/PersetujuanBaru/UsulanBaru.js")
);
const OperatorUsulanUsulanDikirim = React.lazy(() =>
  import(
    "../pages/OperatorLembaga/Persetujuan/PersetujuanBaru/UsulanDikirim.js"
  )
);
const UsulanBelumDitinjauKepalaLembaga = React.lazy(() =>
  import(
    "../pages/OperatorLembaga/Persetujuan/PersetujuanBaru/UsulanBelumDitinjau.js"
  )
);
const ListUsulanOptMonitoring = React.lazy(() =>
  import("../pages/OperatorLembaga/Persetujuan/PersetujuanBaru/ListUsulan")
);

// Operator Pusat Konfigurasi
const KonfigurasiDosen = React.lazy(() =>
  import("../pages/OperatorPusat/Konfigurasi/KonfigurasiDosen")
);
const KonfigurasiDetailDosenPusat = React.lazy(() =>
  import("../pages/OperatorPusat/Konfigurasi/KonfigurasiDosen/DetailDosen.js")
);
const KonfigurasiEditDosenPusat = React.lazy(() =>
  import("../pages/OperatorPusat/Konfigurasi/KonfigurasiDosen/EditDosen.js")
);
const KonfigurasiProdi = React.lazy(() =>
  import("../pages/OperatorPusat/Konfigurasi/KonfigurasiProdi")
);
const KonfigurasiLembaga = React.lazy(() =>
  import("../pages/OperatorPusat/Konfigurasi/KonfigurasiLembaga")
);
const KonfigurasiPerubahanPersonil = React.lazy(() =>
  import("../pages/OperatorPusat/Konfigurasi/KonfigurasiPerubahanPersonil")
);
const KonfigurasiPerubahanPersonilDetail = React.lazy(() =>
  import(
    "../pages/OperatorPusat/Konfigurasi/KonfigurasiPerubahanPersonil/Detail"
  )
);
const KonfigurasiPembatalanUsulan = React.lazy(() =>
  import("../pages/OperatorPusat/Konfigurasi/KonfigurasiPembatalanUsulan")
);
const KonfigurasiPerguruanTinggi = React.lazy(() =>
  import("../pages/OperatorPusat/Konfigurasi/KonfigurasiPerguruanTinggi")
);
const KonfigurasiKopertis = React.lazy(() =>
  import("../pages/OperatorPusat/Konfigurasi/KonfigurasiKopertis")
);
const KonfigurasiKopertisDetailEdit = React.lazy(() =>
  import(
    "../pages/OperatorPusat/Konfigurasi/KonfigurasiKopertis/UpdateDataKopertis"
  )
);
const KonfigurasiKopertisCreateData = React.lazy(() =>
  import(
    "../pages/OperatorPusat/Konfigurasi/KonfigurasiKopertis/CreateDataKopertis"
  )
);

// Operator Pusat Data Referensi
const BidangFokus = React.lazy(() =>
  import("../pages/OperatorPusat/DataReferensi/BidangFokus/index.js")
);
const Skema = React.lazy(() =>
  import("../pages/OperatorPusat/DataReferensi/Skema/index.js")
);
const TahapanKegiatan = React.lazy(() =>
  import("../pages/OperatorPusat/DataReferensi/Tahapan/TahapanKegiatan")
);
const KategoriTahapan = React.lazy(() =>
  import("../pages/OperatorPusat/DataReferensi/Tahapan/KategoriTahapan")
);
const JenisKegiatan = React.lazy(() =>
  import("../pages/OperatorPusat/DataReferensi/JenisKegiatan/index.js")
);
const Peran = React.lazy(() =>
  import("../pages/OperatorPusat/DataReferensi/Peran/index.js")
);

// Operator Pusat Monitoring Monev
const MonitoringMonevPusat = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/Monev/index.js")
);
const MonitoringDetailMonevPUSAT = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/Monev/Detail.js")
);

const MonevPenelitian = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/Monev/PenelitianMonev.js")
);
const MonevPengabdian = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/Monev/PengabdainMonev.js")
);

//Operator Monitoring Evaluasi
const EvaluasiAdministrasi = React.lazy(() =>
  import("../pages/Reviewer/EvaluasiAdministrasi")
);

//Operator Pusat Kosabangsa
const KosangsaPendampingOpPusat = React.lazy(() =>
  import("../pages/OperatorPusat/Kosabangsa/Pendamping")
);
const KosangsaPelaksanaOpPusat = React.lazy(() =>
  import("../pages/OperatorPusat/Kosabangsa/Pelaksana")
);
// Approval LPPM kosabangsa usulan
const PersetujuanKosabangsaUsulanKepalaLembaga = React.lazy(() =>
  import("../pages/OperatorLembaga/Persetujuan/PersetujuanKosabangsaUsulan")
);
const PersetujuanKosabangsaUsulanKepalaLembagaList = React.lazy(() =>
  import(
    "../pages/OperatorLembaga/Persetujuan/PersetujuanKosabangsaUsulan/ListUsulan.js"
  )
);
// Monev Internal PT Vokasi-Dikti
const MonevInternalPTVokasiDikti = React.lazy(() =>
  import("../pages/Operator/Monitoring/MonevInternal/index")
);
// Monev Internal Pusat Vokasi
const MonevInternalPusatVokasi = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/MonevInternal/Vokasi")
);
// Monev Internal Pusat Dikti
const MonevInternalPusatDikti = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/MonevInternal/Dikti")
);
// Pendaftaran akun operator pt
const PendaftaranAkunOperator = React.lazy(() =>
  import("../pages/OperatorPusat/PendaftaranAkunOperatorPT/index.js")
);
//Monev Eksternal v2
const ListKelompokMonevV2 = React.lazy(() => import("pages/MonevV2"));
const ListUsulanBySkemaMonevV2 = React.lazy(() =>
  import("pages/MonevV2/ListUsulanMonev.js")
);

//Kekayaan Intelektual
const MonitoringKI = React.lazy(() => import("pages/KI/Dashboard/Monitroing"));
const KonfigurasiKIJadwalKegiatan = React.lazy(() =>
  import("pages/KI/Konfig/JadwalKegiatan")
);
const KonfigurasiKIWhitelistUsulan = React.lazy(() =>
  import("pages/KI/Konfig/WhitelistUsulan")
);
const KonfigurasiKIInputJadwalKegiatan = React.lazy(() =>
  import("pages/KI/Konfig/JadwalKegiatan/InputJadwalKegiatan")
);
const KonfigurasiKIInputWhitelistUsulan = React.lazy(() =>
  import("pages/KI/Konfig/WhitelistUsulan/InputWhitelistUsulan")
);
// const PlottingFasilitatorKI = React.lazy(() => import("../pages/KI/Plotting")); //comment sementara nanti pasti dugunakan
const PlottingFasilitatorKI = React.lazy(() => import("../pages/KI/PlottingFasilitatorReviewer"));

const PenugasanReviewerKI = React.lazy(() =>
  import("../pages/KI/penugasanReviewer")
);

//report penilaian usulan
const ReportPenilaianUsulan = React.lazy(() =>
  import("../pages/OperatorPusat/Monitoring/Report/PenilaianUsulan/index.js")
);
// Minat katalis
const ApprovalMinatKatalis = React.lazy(() =>
  import("../pages/OperatorPusat/MinatKatalis/Approval/index.js")
);
const MonitoringMinatKatalis = React.lazy(() =>
  import("../pages/Operator/Monitoring/MinatKatalis/index.js")
);
// Admin Panel Penilaian Review
const KomponenPenilaianReview = React.lazy(() =>
  import("../pages/OperatorPusat/AdminPanel/KomponenPenilaianReview/index.js")
);
const KonfigurasiKlaster = React.lazy(() =>
  import("../pages/OperatorPusat/AdminPanel/Klaster/index.js")
);
const KonfigurasiKlasterDetail = React.lazy(() =>
  import("../pages/OperatorPusat/AdminPanel/Klaster/Detail/index.js")
);
const KonfigurasiHomeBaseProdi = React.lazy(() =>
  import("../pages/OperatorPusat/AdminPanel/HomeBaseProdi/index.js")
);
export default [
  // Operator PT - Dashboard
  {
    id: "dashboard-pt",
    label: "Dashboard",
    sequence: 2,
    path: "/operator/dashboard",
    component: DashboardOperator,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  // dashboard operator pusat
  {
    id: "dashboard-pt",
    label: "Dashboard",
    sequence: 2,
    path: "/operator/dashboard",
    component: DashboardOperatorPusat,
    // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  // dashboard operator ADMIN_PROTOTIPE
  {
    id: "dashboard-admin-prototipe",
    label: "Dashboard",
    sequence: 2,
    path: "/operator/dashboard",
    component: DashboardOperatorAdminPrototipe,
    roles: [roleTypeIds.ADMIN_PROTOTIPE],
  },
  //Operator PT - Monitoring
  {
    id: "safsdfsfsdf",
    path: "/operator/testpdf",
    component: GeneratePdfTest,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pt-monitoring",
    label: "Monitoring",
    sequence: 6,
    // hide:true,
    icon: "bx bx-desktop",
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  // usulan baru opt pt start
  {
    id: "operator-monitoring-usulan-baru",
    label: "Usulan Regular",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/usulan",
    // component: OperatorMonitoringUsulanBaru,
    component: DashboardMonitoringUsulan,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru",
    label: "Usulan Regular",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/usulan/:statusUsulan",
    // component: OperatorMonitoringUsulanBaru,
    component: ListUsulanOptMonitoring,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru",
    path: "/operator/monitoring/usulan-baru/usulan-baru",
    component: OperatorMonitoringUsulanBaruDetail,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru",
    path: "/operator/monitoring/usulan-baru/usulan-dikirim",
    component: OperatorMonitoringUsulanBaruUsulanDikirimDetail,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru",
    path: "/operator/monitoring/usulan-baru/usulan-belum-ditinjau",
    component: OperatorMonitoringUsulanBaruUsulanBelumDitinjauDetail,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru",
    path: "/operator/monitoring/usulan-baru/usulan-disetujui",
    component: OperatorMonitoringUsulanBaruUsulanDisetujuiDetail,
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru",
    path: "/operator/monitoring/usulan-baru/usulan-ditolak",
    component: OperatorMonitoringUsulanBaruUsulanDitolakDetail,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru",
    path: "/operator/monitoring/usulan/usulan-hasil-review/v1",
    component: OperatorMonitoringUsulanBaruUsulanDireview,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },

  {
    id: "operator-monitoring-monev",
    label: "Monev",
    parent: "operator-pt-monitoring",
    hide: true,
    path: "/operator-pt/monitoring/monev",
    component: OperatorMonitoringMonev,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-monev",
    path: "/operator-pt/monitoring/monev/detail-monev",
    component: OperatorDetailMonitoringMonev,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },

  // usulan baru opt pt end

  // usulan baru opt pusat start

  {
    id: "operator-monitoring-perbaikan-usulan",
    label: "Perbaikan usulan",
    parent: "operator-pt-monitoring",
    // hide: true,
    path: "/operator/monitoring/perbaikan-usulan",
    component: OperatorMonitoringPerbaikanUsulan,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-usulan-lanjutan",
    label: "Usulan lanjutan",
    parent: "operator-pt-monitoring",
    hide: true,
    path: "/operator/monitoring/usulan-lanjutan",
    component: OperatorMonitoringUsulanLanjutan,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-sptjb",
    label: "SPTJB",
    parent: "operator-pt-monitoring",
    hide: true,
    path: "/operator/monitoring/sptjb",
    component: OperatorMonitoringSptjb,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-catatan-harian",
    label: "Catatan harian",
    parent: "operator-pt-monitoring",
    // hide:false,
    path: "/operator/monitoring/catatan-harian",
    component: OperatorMonitoringCatatanHarian,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-catatan-harian-detail-satu",
    path: "/operator/monitoring/catatan-harian/:id",
    component: OperatorMonitoringCatatanHarianDetailSatu,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-catatan-harian-detail-dua",
    path: "/operator/monitoring/catatan-harian/:id/second-detail/:id",
    component: OperatorMonitoringCatatanHarianDetailDua,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-catatan-harian-detail-tiga",
    path: "/operator/monitoring/catatan-harian/:id/second-detail/:id/last-detail/:id",
    component: OperatorMonitoringCatatanHarianDetailTiga,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-laporan-kemajuan",
    label: "Laporan kemajuan",
    parent: "operator-pt-monitoring",
    // hide:false,
    path: "/operator/monitoring/laporan-kemajuan",
    component: OperatorMonitoringLaporanKemajuan,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-laporan-kemajuan-detail",
    path: "/operator/monitoring/laporan-kemajuan/:id",
    component: OperatorMonitoringLaporanKemajuanDetail,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-laporan-akhir",
    label: "Laporan akhir",
    parent: "operator-pt-monitoring",
    // hide: true,
    path: "/operator/monitoring/laporan-akhir",
    component: OperatorMonitoringLaporanAkhir,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-laporan-akhir-detail",
    path: "/operator/monitoring/laporan-akhir/:id",
    component: OperatorMonitoringLaporanAkhirDetail,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  //Operator Pusat - Monitoring
  {
    id: "operator-pt-monitoring",
    label: "Monitoring",
    sequence: 6,
    // hide: false,
    icon: "bx bx-desktop",
    // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru",
    label: "Usulan Regular",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/usulan-baru",
    // component: OperatorPusatMonitoringUsulanBaru,
    component: DashboardMonitoringUsulan,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru-prototipe",
    label: "Usulan Prototipe",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/usulan-baru-prototipe",
    component: PersetujuanBaruPrototipeKepalaLembaga,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru-prototipe-list-usulan",
    path: "/operator/monitoring/usulan-baru-prototipe/:jenis",
    component: OperatorUsulanPrototipeList,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru",
    path: "/operator/monitoring/usulan-baru/usulan-baru",
    component: OperatorPusatMonitoringUsulanBaruDetail,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },

  {
    id: "operator-monitoring-usulan-baru",
    path: "/operator/monitoring/usulan-baru/usulan-dikirim",
    component: OperatorPusatMonitoringUsulanBaruUsulanDikirimDetail,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru",
    path: "/operator/monitoring/usulan-baru/usulan-belum-ditinjau",
    component: OperatorPusatMonitoringUsulanBaruUsulanBelumDitinjauDetail,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru",
    path: "/operator/monitoring/usulan-baru/usulan-disetujui",
    component: OperatorPusatMonitoringUsulanBaruUsulanDisetujuiDetail,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru",
    path: "/operator/monitoring/usulan-baru/usulan-ditolak",
    component: OperatorPusatMonitoringUsulanBaruUsulanDitolakDetail,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },

  // Monitoring Monev Pusat V2
  {
    id: "operator-monitoring-usulan-baru",
    path: "/operator/monitoring/usulan-baru/:statusUsulan",
    component: ListUsulanOptMonitoring,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  // Monitoring Monev Pusat
  {
    id: "operator-monitoring-monev-pusat",
    label: "Monev",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring-monev",
    component: MonitoringMonevPusat,
    hide: true,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR,
    ],
    sequence: 9,
  },
  {
    id: "operator-monitoring-detail-penelitian-monev",
    path: "/operator/monitoring-monev/detail-penelitian-monev/",
    component: MonevPenelitian,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR,
    ],
  },
  {
    id: "operator-monitoring-detail-pengabdian-monev",
    path: "/operator/monitoring-monev/detail-pengabdian-monev",
    component: MonevPengabdian,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR,
    ],
  },
  {
    id: "operator-monitoring-detail-monev",
    path: "/operator/monitoring/monitoring-monev/detail-monev",
    component: MonitoringDetailMonevPUSAT,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR,
    ],
  },

  // {
  //   id: "operator-monitoring-monev",
  //   label: "Monev",
  //   parent: "operator-lldikti-monitoring",
  //   path: "/operator/monitoring/monev",
  //   component: MonitoringMonevLLDIKTI,
  //   sequence: 8,
  //   hide: true,
  //   roles: [
  //     roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
  //     roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
  //     roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
  //   ],
  // },
  // {
  //   id: "lldikti-monitoring-detail-monev",
  //   path: "/operator/monitoring/monev/detail-monev",
  //   component: MonitoringDetailMonevLLDIKTI,
  //   roles: [
  //     roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
  //     roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
  //     roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
  //   ],
  // },

  // Monitoring Monev Pusat End

  // {
  //   id: "operator-monitoring-usulan-baru",
  //   label: "Usulan baru",
  //   parent: "operator-pt-monitoring",
  //   path: "/operator/monitoring/usulan-baru",
  //   component: OperatorPusatMonitoringUsulanBaru,
  //   // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  //   roles: [
  //     roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR,
  //     roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
  //     roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
  //     roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
  //   ],
  // },
  {
    id: "operator-monitoring-perbaikan-usulan",
    label: "Perbaikan usulan",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/perbaikan-usulan",
    component: OperatorPusatMonitoringPerbaikanUsulan,
    // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-usulan-lanjutan",
    label: "Usulan lanjutan",
    parent: "operator-pt-monitoring",
    hide: true,
    path: "/operator/monitoring/usulan-lanjutan",
    component: OperatorPusatMonitoringUsulanLanjutan,
    // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-catatan-harian",
    label: "Catatan harian",
    parent: "operator-pt-monitoring",
    // hide:false,
    path: "/operator/monitoring/catatan-harian",
    component: OperatorPusatMonitoringCatatanHarian,
    // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-catatan-harian-detail-satu",
    path: "/operator/monitoring/catatan-harian/:id",
    component: OperatorPusatMonitoringCatatanHarianDetailSatu,
    // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-catatan-harian-detail-dua",
    path: "/operator/monitoring/catatan-harian/:id/second-detail/:id",
    component: OperatorPusatMonitoringCatatanHarianDetailDua,
    // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-catatan-harian-detail-tiga",
    path: "/operator/monitoring/catatan-harian/:id/second-detail/:id/last-detail/:id",
    component: OperatorPusatMonitoringCatatanHarianDetailTiga,
    // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-laporan-kemajuan",
    label: "Laporan kemajuan",
    parent: "operator-pt-monitoring",
    // hide:false,
    path: "/operator/monitoring/laporan-kemajuan",
    component: OperatorPusatMonitoringLaporanKemajuan,
    // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-laporan-kemajuan-detail",
    path: "/operator/monitoring/laporan-kemajuan/:id",
    component: OperatorPusatMonitoringLaporanKemajuanDetail,
    // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-laporan-akhir",
    label: "Laporan akhir",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/laporan-akhir",
    component: OperatorPusatMonitoringLaporanAkhir,
    // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-laporan-akhir-detail",
    path: "/operator/monitoring/laporan-akhir/:id",
    component: OperatorPusatMonitoringLaporanAkhirDetail,
    // roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  // Operator - Pengumuman
  {
    id: "operator-news",
    label: "News",
    sequence: 2,
    roles: [
      roleTypeIds.OPERATOR_DIKTI,
      roleTypeIds.SUPER_ADMIN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pengumuman-list",
    label: "Pengumuman",
    parent: "operator-news",
    path: "/operator-pengumuman",
    component: AdminAnnouncement,
    roles: [
      roleTypeIds.OPERATOR_DIKTI,
      roleTypeIds.SUPER_ADMIN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-create-pengumuman",
    path: "/create-pengumuman",
    component: CreatePengumuman,
    roles: [
      roleTypeIds.OPERATOR_DIKTI,
      roleTypeIds.SUPER_ADMIN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-create-pengumuman",
    path: "/update-pengumuman/:id",
    component: EditPengumuman,
    roles: [
      roleTypeIds.OPERATOR_DIKTI,
      roleTypeIds.SUPER_ADMIN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-create-berita",
    path: "/create-berita",
    component: CreateBerita,
    roles: [
      roleTypeIds.OPERATOR_DIKTI,
      roleTypeIds.SUPER_ADMIN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-create-berita",
    path: "/update-berita/:id",
    component: EditBerita,
    roles: [
      roleTypeIds.OPERATOR_DIKTI,
      roleTypeIds.SUPER_ADMIN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-berita-list",
    label: "Berita",
    parent: "operator-news",
    path: "/operator-berita",
    component: AdminBerita,
    roles: [
      roleTypeIds.OPERATOR_DIKTI,
      roleTypeIds.SUPER_ADMIN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-popup-list",
    label: "Poster Pengumuman",
    parent: "operator-news",
    path: "/operator-poster-pengumuman",
    component: AdminPosterPengumuman,
    roles: [
      roleTypeIds.OPERATOR_DIKTI,
      roleTypeIds.SUPER_ADMIN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  // Operator Pengelolaan Reveiwer================
  //Menu
  {
    id: "operator-pengelolaan-reviewer",
    label: "Pengelolaan Reviewer",
    sequence: 8,
    icon: "bx bxs-file me",
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  //Sub Menu Pengelolaan Reviewer
  {
    id: "operator-pengelolaan-reviewer-sk-rektor-reviewer",
    label: "SK Rektor Reviewer",
    parent: "operator-pengelolaan-reviewer",
    path: "/operator/pengelolaan-reviewer/sk-rektor-reviewer",
    hide: true,
    component: SKRektorReviewer,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pengelolaan-reviewer-daftar-reviewer",
    label: "Daftar Reviewer",
    hide: true,
    parent: "operator-pengelolaan-reviewer",
    path: "/operator/pengelolaan-reviewer/daftar-reviewer",
    component: DaftarReviewer,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pengelolaan-reviewer-daftar-reviewer-v2",
    label: "Daftar Reviewer V2",
    parent: "operator-pengelolaan-reviewer",
    path: hideMenu() ? "" : "/operator/v2-pengelolaan-reviewer/daftar-reviewer",
    component: DaftarReviewerV2,
    hide: hideMenu(),
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  // monitoring penilaian
  {
    id: "operator-pengelolaan-reviewer-monitoring-penilaian",
    label: "Monitoring Penilaian",
    parent: "operator-pengelolaan-reviewer",
    path: hideMenu()
      ? ""
      : "/operator/pengelolaan-reviewer/monitoring-penilaian",
    hide: hideMenu(),
    component: MonitoringPenilaian,
    sequence: 7,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  // monitoring penilaian
  {
    id: "operator-pengelolaan-reviewer-monitoring-penilaian-detail",
    parent: "operator-pengelolaan-reviewer",
    path: "/operator/pengelolaan-reviewer/monitoring-penilaian/detail",
    component: MonitoringPenilaianDetail,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pengelolaan-reviewer-daftar-reviewer-eksternal",
    label: "Daftar Reviewer Eksternal",
    parent: "operator-pengelolaan-reviewer",
    path: "/operator/pengelolaan-reviewer/eksternal-daftar-reviewer",
    component: DaftarReviewerEksternal,
    hide: hideMenu(),
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pengelolaan-reviewer-penugasan-reviewer",
    label: "Penugasan Reviewer",
    parent: "operator-pengelolaan-reviewer",
    path: "/operator/pengelolaan-reviewer/penugasan-reviewer",
    component: PenugasanReviewer,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pengelolaan-reviewer-penugasan-reviewer-detail",
    path: "/operator/pengelolaan-reviewer/penugasan-reviewer/detail/:id",
    component: PenugasanReviewerDetail,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pengelolaan-reviewer-penugasan-reviewer-detail-review-internal",
    path: "/operator/pengelolaan-reviewer/penugasan-reviewer/detail/:id/penugasan-reviewer-internal",
    component: PenugasanReviewerInternal,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pengelolaan-reviewer-penugasan-reviewer-detail-review-external",
    path: "/operator/pengelolaan-reviewer/penugasan-reviewer/detail/:id/penugasan-reviewer-external",
    component: PenugasanReviewerExternal,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pengelolaan-reviewer-penugasan-reviewer-beban-reviewer",
    path: "/operator/pengelolaan-reviewer/penugasan-reviewer/beban-reviewer",
    component: PenugasanBebanReviewer,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pengelolaan-reviewer-plotting-reviewer",
    label: "Plotting Reviewer",
    parent: "operator-pengelolaan-reviewer",
    path: "/operator/pengelolaan-reviewer/plotting-reviewer",
    hide: true,
    component: PlottingReviewer,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pengelolaan-reviewer-plotting-reviewer-detail",
    path: "/operator/pengelolaan-reviewer/plotting-reviewer/detail",
    component: PlottingReviewerDetail,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pengelolaan-reviewer-plotting-reviewer-beban-reviewer",
    path: "/operator/pengelolaan-reviewer/plotting-reviewer/beban-reviewer",
    component: PlottingBebanReviewer,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  // Plotting Reviwer PT V2
  {
    id: "operator-pengelolaan-reviewer-plotting-reviewer-v2",
    label: "Plotting Reviewer V2",
    parent: "operator-pengelolaan-reviewer",
    path: hideMenu()
      ? ""
      : "/operator/v2-pengelolaan-reviewer/plotting-reviewer",
    component: PlottingReviewerV2,
    hide: hideMenu(),
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pengelolaan-reviewer-plotting-reviewer-detail-v2",
    path: hideMenu()
      ? ""
      : "/operator/v2-pengelolaan-reviewer/plotting-reviewer/detail",
    component: PlottingReviewerDetailV2,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  // END OF Pengelolaan Reveiwer===============

  // Operator pusat pengelolaan reviewer
  //Menu
  {
    id: "operator-pusat-pengelolaan-reviewer",
    label: "Pengelolaan Reviewer",
    sequence: 8,
    icon: "bx bxs-file me",
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  //Sub Menu Pengelolaan Reviewer
  {
    id: "operator-pusat-pengelolaan-reviewer-sk-rektor-reviewer",
    label: "SK Rektor Reviewer",
    parent: "operator-pusat-pengelolaan-reviewer",
    path: "/operator-pusat/pengelolaan-reviewer/sk-rektor-reviewer",
    component: OperatorPusatSKRektorReviewer,
    hide: true,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-pengelolaan-reviewer-daftar-reviewer",
    label: "Daftar Reviewer",
    parent: "operator-pusat-pengelolaan-reviewer",
    path: "/operator-pusat/pengelolaan-reviewer/daftar-reviewer",
    component: OperatorPusatDaftarReviewer,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-pengelolaan-reviewer-daftar-reviewer-eksternal",
    label: "Daftar Reviewer Eksternal",
    parent: "operator-pusat-pengelolaan-reviewer",
    path: "/operator/pengelolaan-reviewer/eksternal-daftar-reviewer",
    component: DaftarReviewerEksternal,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-pengelolaan-reviewer-penugasan-reviewer",
    label: "Penugasan Reviewer",
    parent: "operator-pusat-pengelolaan-reviewer",
    path: "/operator-pusat/pengelolaan-reviewer/penugasan-reviewer",
    component: OperatorPusatPenugasanReviewer,
    hide: true,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-pengelolaan-reviewer-penugasan-reviewer-detail",
    path: "/operator-pusat/pengelolaan-reviewer/penugasan-reviewer/detail/:id",
    component: OperatorPusatPenugasanReviewerDetail,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-pengelolaan-reviewer-penugasan-reviewer-detail-review-internal",
    path: "/operator-pusat/pengelolaan-reviewer/penugasan-reviewer/detail/:id/penugasan-reviewer-internal",
    component: OperatorPusatPenugasanReviewerInternal,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-pengelolaan-reviewer-penugasan-reviewer-detail-review-external",
    path: "/operator-pusat/pengelolaan-reviewer/penugasan-reviewer/detail/:id/penugasan-reviewer-external",
    component: OperatorPusatPenugasanReviewerExternal,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-pengelolaan-reviewer-penugasan-reviewer-beban-reviewer",
    path: "/operator-pusat/pengelolaan-reviewer/penugasan-reviewer/beban-reviewer",
    component: OperatorPusatPenugasanBebanReviewer,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-pengelolaan-reviewer-plotting-reviewer",
    label: "Plotting Reviewer",
    parent: "operator-pusat-pengelolaan-reviewer",
    path: "/operator-pusat/pengelolaan-reviewer/plotting-reviewer",
    component: OperatorPusatPlottingReviewer,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-pengelolaan-reviewer-plotting-reviewer-detail",
    path: "/operator-pusat/pengelolaan-reviewer/plotting-reviewer/detail",
    component: OperatorPusatPlottingReviewerDetail,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-pengelolaan-reviewer-plotting-reviewer-beban-reviewer",
    path: "/operator-pusat/pengelolaan-reviewer/plotting-reviewer/beban-reviewer",
    component: OperatorPusatPlottingBebanReviewer,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  // plotting reviewer v2
  {
    id: "operator-pusat-pengelolaan-reviewer-plotting-reviewer-v2",
    label: "Plotting Reviewer V2",
    parent: "operator-pusat-pengelolaan-reviewer",
    path: "/operator-pusat/pengelolaan-reviewer/v2/plotting-reviewer",
    component: OperatorPusatPlottingReviewerV2,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  // search plotting reviewer v2
  {
    id: "operator-pusat-pengelolaan-reviewer-search-plotting-reviewer-v2",
    label: "Search Plotting Reviewer V2",
    parent: "operator-pusat-pengelolaan-reviewer",
    path: "/operator-pusat/pengelolaan-reviewer/v2/search-plotting-reviewer",
    component: OperatorPusatSearchPlottingReviewerV2,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-pengelolaan-reviewer-plotting-reviewer-detail-v2",
    path: "/operator-pusat/pengelolaan-reviewer/v2/plotting-reviewer/detail",
    component: OperatorPusatPlottingReviewerDetailV2,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-pengelolaan-reviewer-plotting-reviewer-beban-reviewer-v2",
    path: "/operator-pusat/pengelolaan-reviewer/v2/plotting-reviewer/beban-reviewer",
    component: OperatorPusatPlottingBebanReviewerV2,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  // End of operator pusat pengelolaan reviwer

  // Operator - Data Pendukung +++++++++++++++++
  {
    id: "operator-data-pendukung",
    label: "Data Pendukung",
    sequence: 7,
    icon: "bx bxs-file me",
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-data-pendukung-profile-lembaga",
    label: "Profile Lembaga",
    parent: "operator-data-pendukung",
    path: "/operator/data-pendukung/profil-lembaga",
    component: ProfilLembaga,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-data-pendukung-cari-akun-dosen",
    label: "Cari Akun",
    parent: "operator-data-pendukung",
    path: "/operator/data-pendukung/cari-akun-dosen",
    component: CariAkunDosen,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-data-pendukung-sinkronasi-prodi",
    label: "Sinkronasi Prodi",
    parent: "operator-data-pendukung",
    path: "/operator/data-pendukung/sinkronisasi-prodi",
    component: SinkronisasiProdi,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-data-pendukung-sinkronasi-dosen",
    label: "Sinkronasi Dosen",
    parent: "operator-data-pendukung",
    path: "/operator/data-pendukung/sinkronisasi-dosen",
    component: SinkronisasiDosen,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-data-pendukung-detail-dosen",
    path: "/operator/data-pendukung/sinkronisasi-dosen/:id",
    component: DetailDosen,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-data-pendukung-edit-dosen",
    path: "/operator/data-pendukung/sinkronisasi-dosen/edit-dosen/:id",
    component: EditDosen,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pt-monitoring-persetujuan-usulan",
    label: "Persetujuan Usulan",
    sequence: 3,
    icon: "bx bx-task",
    hide: true,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-pt-monitoring-persetujuan-usulan-baru",
    label: "Usulan Regular",
    parent: "operator-pt-monitoring-persetujuan-usulan",
    path: "/persetujuan/persetujuan-usulan/:statusUsulan",
    component: ListUsulanOptMonitoring,
    sequence: 1,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  // End Of Operator - Data Pendukung -------------------

  // OPERATOR_DIKTI
  {
    id: "penugasan",
    label: "Program Lainnya",
    sequence: 4,
    icon: "bx bxs-file me",
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  {
    id: "penugasan-nusantara",
    label: "Call for Proposal Nusantara",
    parent: "penugasan",
    path: "/penugasan/nusantara",
    component: PenugasanNusantara,
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  {
    id: "penugasan-nusantara-detail",
    path: "/penugasan/nusantara/:id",
    component: PenugasanNusantara,
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  {
    id: "penugasan-prototipe",
    label: "Call for Proposal Prototipe",
    parent: "penugasan",
    // hide: true,
    path: "/penugasan/prototipe",
    component: PenugasanPrototipe,
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  {
    id: "penugasan-prototipe-detail",

    path: "/penugasan/prototipe/:id",
    component: PenugasanPrototipe,
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  {
    id: "penugasan-jfs",
    label: "Call for Proposal JFS SEA-EU",
    // hide: true,
    parent: "penugasan",
    path: "/penugasan/jfs-sea-eu",
    component: PenugasanJfsSeaEu,
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  {
    id: "penugasan-jfs-detail",
    path: "/penugasan/jfs-sea-eu/:id",
    component: PenugasanJfsSeaEu,
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  {
    id: "penugasan-google-bangkit",
    label: "Proposal Penugasan (Google Bangkit)",
    // hide: true,
    parent: "penugasan",
    path: "/penugasan/google-bangkit",
    component: PenugasanGoogleBangkit,
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  {
    id: "penugasan-google-bangkit-detail",
    path: "/penugasan/google-bangkit/:id",
    component: PenugasanGoogleBangkit,
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  {
    id: "penugasan-modul-pembelajaran",
    label: "Proposal Penugasan (Modul Pembelajaran)",
    // hide: true,
    parent: "penugasan",
    path: "/penugasan/modul-pembelajaran",
    component: PenugasanModulPembelajaran,
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  {
    id: "penugasan-modul-pembelajaran-detail",
    path: "/penugasan/modul-pembelajaran/:id",
    component: PenugasanModulPembelajaran,
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  {
    id: "penugasan-kosabangsa",
    label: "Proposal Penugasan Kosabangsa",
    // hide: false,
    parent: "penugasan",
    path: "/penugasan/kosabangsa",
    component: PenugasanKosaBangsa,
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  {
    id: "penugasan-kosabangsa-detail",
    path: "/penugasan/kosabangsa/:id",
    component: PenugasanKosaBangsa,
    parent: "penugasan",
    roles: [roleTypeIds.OPERATOR_DIKTI],
  },
  // Change Password +++++++++++++
  {
    id: "change-password",
    path: "/change-password",
    component: ChangePassword,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.DOSEN,
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  // End Of Change Password +++++++++++++
  // Operator Pusat
  {
    id: "operator-pusat-pengguna",
    label: "Pengguna",
    sequence: 6,
    icon: "bx bxs-user me",
    hide: true,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-data-pendukung-kontak-pic",
    label: "Kontak PIC",
    path: "/operator/kontak-pic",
    parent: "operator-pusat-pengguna",
    component: KontakPIC,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  // End of Operator Pusat

  // OPT LLDIKTI START
  {
    id: "dashboard-operator-lldikti",
    label: "Dashboard",
    sequence: 1,
    path: "/operator/dashboard",
    component: DashboardLLDIKTI,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-lldikti-monitoring",
    label: "Monitoring",
    hide: false,
    sequence: 2,
    icon: "bx bx-desktop",
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-monitoring-laporan-kemajuan",
    label: "Laporan kemajuan",
    parent: "operator-lldikti-monitoring",
    path: "/operator/monitoring/laporan-kemajuan",
    component: OperatorLLDIKTIMonitoringLaporanKemajuan,
    sequence: 4,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-monitoring-laporan-kemajuan",
    path: "/operator/monitoring/laporan-kemajuan/:id",
    component: OperatorLLDIKTIMonitoringLaporanDetail,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-monitoring-perbaikan-usulan",
    label: "Perbaikan usulan",
    parent: "operator-lldikti-monitoring",
    // hide: true,
    path: "/operator/monitoring/perbaikan-usulan",
    component: OperatorLLDIKTIMonitoringPerbaikanUsulan,
    sequence: 2,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-monitoring-catatan-harian",
    label: "Catatan harian",
    parent: "operator-lldikti-monitoring",
    path: "/operator/monitoring/catatan-harian",
    component: OperatorLLDIKTIMonitoringCatatanHarian,
    sequence: 3,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-monitoring-catatan-harian-detail-satu",
    path: "/operator/monitoring/catatan-harian/:id",
    component: OperatorLLDIKTIMonitoringCatatanHarianDetailSatu,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-monitoring-catatan-harian-detail-dua",
    path: "/operator/monitoring/catatan-harian/:id/second-detail/:id",
    component: OperatorLLDIKTIMonitoringCatatanHarianDetailDua,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-monitoring-catatan-harian-detail-tiga",
    path: "/operator/monitoring/catatan-harian/:id/second-detail/:id/last-detail/:id",
    component: OperatorLLDIKTIMonitoringCatatanHarianDetailTiga,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-monitoring-laporan-akhir",
    label: "Laporan akhir",
    parent: "operator-lldikti-monitoring",
    path: "/operator/monitoring/laporan-akhir",
    component: OperatorLLDIKTIMonitoringLaporanAkhir,
    sequence: 6,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-monitoring-laporan-akhir-detail",
    path: "/operator/monitoring/laporan-akhir/:id",
    component: OperatorLLDIKTIMonitoringLaporanAkhirDetail,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-lldikti-konfigurasi",
    label: "Konfigurasi",
    sequence: 3,
    hide: true,
    icon: "bx bx-cog",
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-lldikti-konfigurasi-dosen",
    label: "Konfigurasi Dosen",
    parent: "operator-lldikti-konfigurasi",
    path: "/operator/konfigurasi-dosen",
    component: KonfigurasiDosenLLDIKTI,
    sequence: 4,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-lldikti-konfigurasi-dosen",
    path: "/operator/konfigurasi-dosen/sinkronisasi-dosen/:id",
    component: KonfigurasiDetailDosenLLDIKTI,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-lldikti-konfigurasi-dosen",
    path: "/operator/konfigurasi-dosen/sinkronisasi-dosen/edit-dosen/:id",
    component: KonfigurasiEditDosenLLDIKTI,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-lldikti-perguruan-tinggi",
    label: "Konfigurasi Perguruan Tinggi",
    parent: "operator-lldikti-konfigurasi",
    path: "/operator/konfigurasi-perguruan-tinggi",
    sequence: 3,
    component: KonfigurasPerguruanTinggiLLDIKTI,

    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-lldikti-konfigurasi-prodi",
    label: "Konfigurasi Prodi",
    parent: "operator-lldikti-konfigurasi",
    path: "/operator/konfigurasi-prodi",
    component: KonfigurasiProdiLLDIKTI,
    sequence: 2,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-lldikti-konfigurasi-lembaga",
    label: "Konfigurasi Lembaga",
    parent: "operator-lldikti-konfigurasi",
    path: "/operator/konfigurasi-lembaga",
    component: KonfigurasiProfilLembagaLLDIKTI,
    sequence: 1,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-lldikti-monitoring-persetujuan-usulan-baru",
    label: "Usulan Regular",
    parent: "operator-lldikti-monitoring",
    path: "/persetujuan/persetujuan-usulan",
    component: DashboardMonitoringUsulan,
    sequence: 1,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "lldikti-monitoring-persetujuan-usulan-baru",
    path: "/persetujuan/persetujuan-usulan/usulan-baru",
    component: UsulanBaruLLDIKTI,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "lldikti-monitoring-persetujuan-usulan-dikirim",
    path: "/persetujuan/persetujuan-usulan/usulan-dikirim",
    component: UsulanDikirimLLDIKTI,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "lldikti-monitoring-persetujuan-usulan-belum-ditinjau",
    path: "/persetujuan/persetujuan-usulan/usulan-belum-ditinjau",
    component: UsulanBelumDitinjauLLDIKTI,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "lldikti-monitoring-persetujuan-usulan-disetujui",
    path: "/persetujuan/persetujuan-usulan/usulan-disetujui",
    component: UsulanDisetujuiLLDIKTI,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "lldikti-monitoring-persetujuan-usulan-ditolak",
    path: "/persetujuan/persetujuan-usulan/usulan-ditolak",
    component: UsulanDitolakLLDIKTI,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "operator-monitoring-monev",
    label: "Monev",
    parent: "operator-lldikti-monitoring",
    path: "/operator/monitoring/monev",
    component: MonitoringMonevLLDIKTI,
    sequence: 8,
    hide: true,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  {
    id: "lldikti-monitoring-detail-monev",
    path: "/operator/monitoring/monev/detail-monev",
    component: MonitoringDetailMonevLLDIKTI,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  // OPT LLDIKTI END

  // Kepala Lembaga START
  {
    id: "dashboard-kepala-lembaga",
    label: "Dashboard",
    sequence: 1,
    path: "/operator/dashboard",
    component: DashboardKepalaLembaga,
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  {
    id: "kepala-lembaga-monitoring-persetujuan-usulan",
    label: "Persetujuan Usulan",
    sequence: 6,
    icon: "bx bx-task",
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  {
    id: "kepala-lembaga-monitoring-persetujuan-usulan-baru",
    label: "Usulan Reguler",
    parent: "kepala-lembaga-monitoring-persetujuan-usulan",
    path: "/persetujuan/persetujuan-usulan-kepala-lembaga",
    component: DashboardMonitoringUsulan,
    sequence: 1,
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  {
    id: "kepala-lembaga-monitoring-persetujuan-prototipe",
    label: "Usulan Prototipe",
    parent: "kepala-lembaga-monitoring-persetujuan-usulan",
    path: "/persetujuan/persetujuan-prototipe-kepala-lembaga",
    component: PersetujuanBaruPrototipeKepalaLembaga,
    sequence: 2,
    // hide: process.env.REACT_APP_NODE === "production",
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  // ADMIN_PROTOTIPE (Khusus)
  {
    id: "admin-prototipe-persetujuan-usulan",
    label: "Persetujuan Usulan",
    sequence: 7,
    icon: "bx bx-task",
    roles: [roleTypeIds.ADMIN_PROTOTIPE],
  },
  {
    id: "admin-prototipe-persetujuan-usulan-baru",
    label: "Usulan Prototipe",
    parent: "admin-prototipe-persetujuan-usulan",
    path: "/persetujuan/persetujuan-prototipe-kepala-lembaga",
    component: PersetujuanBaruPrototipeKepalaLembaga,
    // hide: process.env.REACT_APP_NODE === "production",
    roles: [roleTypeIds.ADMIN_PROTOTIPE],
  },
  {
    id: "kepala-lembaga-monitoring-persetujuan-list-usulan",
    path: "/persetujuan/persetujuan-prototipe-kepala-lembaga/:jenis",
    component: OperatorUsulanPrototipeList,
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
      roleTypeIds.ADMIN_PROTOTIPE,
    ],
  },
  {
    id: "kepala-lembaga-monitoring-persetujuan-usulan-disetujui",
    path: "/persetujuan/persetujuan-usulan-kepala-lembaga/usulan-disetujui",
    component: OperatorUsulanDisetujui,
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
      roleTypeIds.ADMIN_PROTOTIPE,
    ],
  },
  {
    id: "kepala-lembaga-monitoring-persetujuan-usulan-ditolak",
    path: "/persetujuan/persetujuan-usulan-kepala-lembaga/usulan-ditolak",
    component: OperatorUsulanDitolak,
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
      roleTypeIds.ADMIN_PROTOTIPE,
    ],
  },
  {
    id: "kepala-lembaga-monitoring-persetujuan-usulan-usulan-baru",
    path: "/persetujuan/persetujuan-usulan-kepala-lembaga/usulan-baru",
    component: OperatorUsulanUsulanBaru,
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
      roleTypeIds.ADMIN_PROTOTIPE,
    ],
  },
  {
    id: "kepala-lembaga-monitoring-persetujuan-usulan-usulan-dikirim",
    path: "/persetujuan/persetujuan-usulan-kepala-lembaga/usulan-dikirim",
    component: OperatorUsulanUsulanDikirim,
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
      roleTypeIds.ADMIN_PROTOTIPE,
    ],
  },
  {
    id: "kepala-lembaga-monitoring-persetujuan-usulan-usulan-belum-ditinjau",
    path: "/persetujuan/persetujuan-usulan-kepala-lembaga/usulan-belum-ditinjau",
    component: UsulanBelumDitinjauKepalaLembaga,
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
      roleTypeIds.ADMIN_PROTOTIPE,
    ],
  },
  //List Usulan untuk versi 2
  {
    id: "kepala-lembaga-monitoring-persetujuan-usulan-usulan-belum-ditinjau",
    path: "/persetujuan/persetujuan-usulan-kepala-lembaga/:statusUsulan",
    component: ListUsulanOptMonitoring,
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  {
    id: "kepala-lembaga-monitoring-persetujuan-usulan-usulan-belum-ditinjau",
    path: "/persetujuan/persetujuan-usulan/:statusUsulan",
    component: ListUsulanOptMonitoring,
    roles: [
      roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN,
      roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN,
      roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI,
    ],
  },
  // Kepala Lembaga END

  // * Operator Pusat Konfigurasi
  {
    id: "operator-pusat-konfigurasi",
    label: "Konfigurasi",
    sequence: 8,
    hide: true,
    icon: "bx bx-cog",
    roles: [
      roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR,
      roleTypeIds.OPERATOR_PUSAT_IT_SUPPORT,
    ], // Bug (Label keluar 2)
  },
  {
    id: "operator-pusat-konfigurasi-dosen",
    label: "Konfigurasi Dosen",
    parent: "operator-pusat-konfigurasi",
    path: "/operator/konfigurasi-dosen",
    component: KonfigurasiDosen,
    roles: [roleTypeIds.OPERATOR_PUSAT_IT_SUPPORT],
  },
  {
    id: "operator-pusat-konfigurasi-dosen",
    path: "/operator/konfigurasi-dosen/sinkronisasi-dosen/:id",
    component: KonfigurasiDetailDosenPusat,
    roles: [roleTypeIds.OPERATOR_PUSAT_IT_SUPPORT],
  },
  {
    id: "operator-pusat-konfigurasi-dosen",
    path: "/operator/konfigurasi-dosen/sinkronisasi-dosen/edit-dosen/:id",
    component: KonfigurasiEditDosenPusat,
    roles: [roleTypeIds.OPERATOR_PUSAT_IT_SUPPORT],
  },
  {
    id: "operator-pusat-konfigurasi-prodi",
    label: "Konfigurasi Prodi",
    parent: "operator-pusat-konfigurasi",
    path: "/operator/konfigurasi-prodi",
    component: KonfigurasiProdi,
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "operator-pusat-konfigurasi-lembaga",
    label: "Konfigurasi Lembaga",
    parent: "operator-pusat-konfigurasi",
    path: "/operator/konfigurasi-lembaga",
    component: KonfigurasiLembaga,
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "operator-pusat-konfigurasi-perubahan-personil",
    label: "Konfigurasi Perubahan Personil",
    parent: "operator-pusat-konfigurasi",
    path: "/operator/konfigurasi-perubahan-personil",
    component: KonfigurasiPerubahanPersonil,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-konfigurasi-perubahan-personil",
    path: "/operator/konfigurasi-perubahan-personil/:id",
    component: KonfigurasiPerubahanPersonilDetail,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-konfigurasi-pembatalan-usulan",
    label: "Konfigurasi Pembatalan Usulan",
    parent: "operator-pusat-konfigurasi",
    path: "/operator/konfigurasi-pembatalan-usulan",
    component: KonfigurasiPembatalanUsulan,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-pusat-konfigurasi-perguruan-tinggi",
    label: "Konfigurasi Perguruan Tinggi",
    parent: "operator-pusat-konfigurasi",
    path: "/operator/konfigurasi-perguruan-tinggi",
    component: KonfigurasiPerguruanTinggi,
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "operator-pusat-konfigurasi-kopertis",
    label: "Konfigurasi Kopertis",
    parent: "operator-pusat-konfigurasi",
    path: "/operator/konfigurasi-kopertis",
    component: KonfigurasiKopertis,
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "operator-pusat-konfigurasi-kopertis-detail",
    path: "/operator/konfigurasi-kopertis/:id",
    component: KonfigurasiKopertisDetailEdit,
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "operator-pusat-konfigurasi-kopertis-detail",
    path: "/operator/konfigurasi-kopertis/create",
    component: KonfigurasiKopertisCreateData,
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },

  // * Operator Pusat Data Referensi START
  {
    id: "operator-pusat-data-referensi",
    label: "Data Referensi",
    sequence: 9,
    hide: true,
    icon: "bx bx-book",
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "operator-pusat-data-referensi-bidang-fokus",
    label: "Bidang Fokus",
    parent: "operator-pusat-data-referensi",
    path: "/operator/data-referensi/bidang-fokus",
    component: BidangFokus,
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "operator-pusat-data-referensi-skema",
    label: "Skema",
    parent: "operator-pusat-data-referensi",
    path: "/operator/data-referensi/skema",
    component: Skema,
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "operator-pusat-data-referensi-tahapan-kegiatan",
    label: "Tahapan Kegiatan",
    parent: "operator-pusat-data-referensi",
    path: "/operator/data-referensi/tahapan-kegiatan",
    component: TahapanKegiatan,
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "operator-pusat-data-referensi-kategori-tahapan",
    label: "Kategori Tahapan",
    parent: "operator-pusat-data-referensi",
    path: "/operator/data-referensi/kategori-tahapan",
    component: KategoriTahapan,
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "operator-pusat-data-referensi-jenis-kegiatan",
    label: "Jenis Kegiatan",
    parent: "operator-pusat-data-referensi",
    path: "/operator/data-referensi/jenis-kegiatan",
    component: JenisKegiatan,
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "operator-pusat-data-referensi-peran",
    label: "Peran",
    parent: "operator-pusat-data-referensi",
    path: "/operator/data-referensi/peran",
    component: Peran,
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  // * Operator Pusat Data Referensi END
  // Operator Pusat Kosabangsa
  {
    id: "kosabangsa-op-pusat",
    label: "Kosabangsa",
    sequence: 5,
    icon: "bx bx-shape-circle",
    // hide: process.env.REACT_APP_NODE === "production",
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "kosabangsa-op-pusat-pendamping",
    label: "Approval Pendaftaran Pendamping",
    parent: "kosabangsa-op-pusat",
    component: KosangsaPendampingOpPusat,
    path: "/operator/kosabangsa/pendamping",
    // hide: process.env.REACT_APP_NODE === "production",
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  {
    id: "kosabangsa-op-pusat-pelaksana",
    label: "Approval Pendaftaran Pelaksana",
    parent: "kosabangsa-op-pusat",
    component: KosangsaPelaksanaOpPusat,
    path: "/operator/kosabangsa/pelaksana",
    // hide: process.env.REACT_APP_NODE === "production",
    roles: [roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR],
  },
  // Approval LPPM Kosabangsa Usulan
  {
    id: "kepala-lembaga-monitoring-persetujuan-kosabangsa-usulan",
    label: "Usulan Kosabangsa",
    parent: "kepala-lembaga-monitoring-persetujuan-usulan",
    path: "/persetujuan/persetujuan-kosabangsa-usulan-kepala-lembaga",
    component: PersetujuanKosabangsaUsulanKepalaLembaga,
    sequence: 3,
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  {
    id: "kepala-lembaga-monitoring-persetujuan-kosabangsa-usulan-list",
    path: "/persetujuan/persetujuan-kosabangsa-usulan-kepala-lembaga/:jenis",
    component: PersetujuanKosabangsaUsulanKepalaLembagaList,
    roles: [
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  // Monitoring Operator Kosabangsa Usulan
  {
    id: "operator-monitoring-usulan-kosabangsa-kolaborasi",
    label: "Usulan Kosabangsa Kolaborasi",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/usulan-kosabangsa-kolaborasi",
    component: PersetujuanKosabangsaUsulanKepalaLembaga,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  {
    id: "operator-monitoring-usulan-baru-prototipe-list-usulan",
    path: "/operator/monitoring/usulan-kosabangsa-kolaborasi/:jenis",
    component: PersetujuanKosabangsaUsulanKepalaLembagaList,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  // Monev Internal PT Vokasi Dikti
  {
    id: "operator-monitoring-monev-internal-pt",
    label: "Monev Internal PT",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/monev-internal-pt",
    component: MonevInternalPTVokasiDikti,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN_VOKASI,
      roleTypeIds.OPERATOR_PT_PENGABDIAN_VOKASI,
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
    ],
  },
  // Monev internal pusat vokasi
  {
    id: "operator-monitoring-monev-internal-pusat",
    label: "Monev Internal Pusat",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/monev-internal-pusat",
    component: MonevInternalPusatVokasi,
    roles: [roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV],
  },
  /// Monev internal pusat dikti
  {
    id: "operator-monitoring-monev-internal-pusat",
    label: "Monev Internal Pusat",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/monev-internal-pusat",
    component: MonevInternalPusatDikti,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  // report penilaian usulan
  {
    id: "operator-monitoring-report-penilaian-usulan",
    label: "Report Penilaian Usulan",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/report-penilaian-usulan",
    component: ReportPenilaianUsulan,
    roles: [
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-evaluasi-administrasi-pusat",
    label: "Evaluasi Administrasi Pusat",
    parent: "operator-pt-monitoring",
    path: "/operator/evaluasi-administrasi",
    component: EvaluasiAdministrasi,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },

  //Monev V2 Eksternal
  {
    id: "operator-monitoring-monev-eksternal",
    path: "/operator/monitoring/monev-eksternal/:jenis_kegiatan",
    component: ListKelompokMonevV2,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-monev-eksternal-list-usulan",
    path: "/operator/monitoring/monev-eksternal/:jenis_kegiatan/list-usulan/:skemaId",
    component: ListUsulanBySkemaMonevV2,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-monitoring-monev-eksternal-pengabdian",
    label: "Monev Eksternal Pengabdian",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/monev-eksternal/pengabdian",
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  // Pembuatan akun operator PT
  {
    id: "operator-pengelolaan-reviewer",
    label: "Pendaftaran Akun PT",
    sequence: 9,
    icon: "bx bxs-file me",
    path: "/operator/pendaftaran/akun-operator-pt",
    component: PendaftaranAkunOperator,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },

  //KI
  {
    id: "operator-pusat-dashboard-ki",
    label: "Dashboard",
    path: "/dashboard",
    component: MonitoringKI,
    roles: [roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "operator-pusat-konfigurasi-ki",
    label: "Konfigurasi",
    icon: "fa fa-cogs",
    roles: [roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "operator-pusat-konfigurasi-jadwal-ki",
    label: "Jadwal Kegiatan",
    parent: "operator-pusat-konfigurasi-ki",
    path: "/konfigurasi-ki/jadwal-kegiatan",
    component: KonfigurasiKIJadwalKegiatan,
    roles: [roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "operator-pusat-konfigurasi-whitelist-ki",
    label: "Whitelist Usulan",
    parent: "operator-pusat-konfigurasi-ki",
    path: "/konfigurasi-ki/whitelis-usulan",
    component: KonfigurasiKIWhitelistUsulan,
    roles: [roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "operator-pusat-konfigurasi-jadwal-ki-input",
    path: "/konfigurasi-ki/jadwal-kegiatan/input",
    component: KonfigurasiKIInputJadwalKegiatan,
    roles: [roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "operator-pusat-konfigurasi-jadwal-ki-input",
    path: "/konfigurasi-ki/jadwal-kegiatan/input/:id?",
    component: KonfigurasiKIInputJadwalKegiatan,
    roles: [roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "operator-pusat-konfigurasi-whitlist-ki-input",
    path: "/konfigurasi-ki/whitelist-usulan/input",
    component: KonfigurasiKIInputWhitelistUsulan,
    roles: [roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "operator-pusat-konfigurasi-whitlist-ki-input-edit",
    path: "/konfigurasi-ki/whitelist-usulan/input/:id?",
    component: KonfigurasiKIInputWhitelistUsulan,
    roles: [roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "operator-pusat-ki-kelola-reviewer",
    label: "Pengelolaan Reviewer",
    icon: "fa fa-cogs",
    roles: [roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "penugasan-fasilitator",
    path: "/penugasan-fasilitator-ki",
    label: "Penugasan Reviewer",
    parent: "operator-pusat-ki-kelola-reviewer",
    component: PenugasanReviewerKI,
    roles: [roleTypeIds.OPERATOR_PUSAT_KI],
  },
  {
    id: "plotting-fasilitator-ki",
    path: "/plotting-fasilitator-ki",
    label: "Plotting Fasilitator",
    parent: "operator-pusat-ki-kelola-reviewer",
    component: PlottingFasilitatorKI,
    roles: [roleTypeIds.OPERATOR_PUSAT_KI],
  },

  // Approval minat katalis
  {
    id: "operator-minat-katalis",
    label: "Minat Katalis",
    sequence: 8,
    icon: "bx bx-shape-circle",
    roles: [
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  {
    id: "operator-approval-minat-katalis",
    label: "Approval Minat Katalis",
    parent: "operator-minat-katalis",
    path: "/operator/approval/minat-katalis",
    component: ApprovalMinatKatalis,
    roles: [
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
  },
  // monitoring minat katalis
  {
    id: "operator-monitoring-minat-katalis",
    label: "Minat Katalis",
    parent: "operator-pt-monitoring",
    path: "/operator/monitoring/minat-katalis",
    component: MonitoringMinatKatalis,
    roles: [
      roleTypeIds.OPERATOR_PT_PENELITIAN,
      roleTypeIds.OPERATOR_PT_PENGABDIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENELITIAN,
      roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN,
    ],
  },
  // Admin panel
  {
    id: "operator-pusat-admin-panel",
    label: "Admin Panel",
    icon: "fa fa-cogs",
    sequence: 12,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  // komponen penilaian
  {
    id: "operator-admin-panel-komponen-penilaian-review",
    label: "Komponen Penilaian",
    parent: "operator-pusat-admin-panel",
    path: "/operator/admin-panel/komponen-penilaian-review",
    component: KomponenPenilaianReview,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  // komponen penilaian
  {
    id: "operator-admin-panel-konfigurasi-klaster",
    label: "Konfigurasi Klaster",
    parent: "operator-pusat-admin-panel",
    path: "/operator/admin-panel/konfigurasi-klaster",
    component: KonfigurasiKlaster,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  {
    id: "operator-admin-panel-konfigurasi-klaster-detail",
    path: "/operator/admin-panel/konfigurasi-klaster/detail/:kd_klaster",
    component: KonfigurasiKlasterDetail,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
  // konfigurasi homebase prodi
  {
    id: "operator-admin-panel-konfigurasi-homebase-prodi",
    path: "/operator/admin-panel/konfigurasi-homebase-prodi",
    label: "Konfigurasi HomeBase",
    parent: "operator-pusat-admin-panel",
    component: KonfigurasiHomeBaseProdi,
    roles: [
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
      roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV,
    ],
  },
];
